export function zeros(count) {
    let arr = new Array(count)
    for (let i=0; i < count; i++) {
        arr[i] = 0
    }
    return arr;
}

export function findRemove(arr, fn) {
    for (let idx=0; idx < arr.length; idx++) {
        if (fn(arr[idx])) {
            arr.splice(idx, 1);
            return
        }
    }
}


export function removeItemOnce(arr, value) {
    let index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}


export function arrayEquals(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}
