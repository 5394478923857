import {withoutNulls} from "../lib/objects";

export default {
    newState(init) {
        return {
            empPattern: this.fromApi(init && init.emp_pattern, true),
        }
    },
    default(common) {
        let pattern = {
            'min_work': null, 'max_work': null,
            'min_free': null, 'max_free': null,
        };
        if (!common) {
            pattern.standard = true;
        }
        return pattern
    },
    fromApi(initPattern, common) {
        let pattern = this.default(common);
        if (initPattern && (common || !initPattern.standard)) {
            Object.assign(pattern, initPattern)
        }
        return pattern;
    },
    toApi(state, common) {
        if (!common && state.standard) {
            return {'standard': true};
        } else {
            return withoutNulls(state);
        }
    }
}
