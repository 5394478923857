<template>
<tbody class="tbody_shifts">
  <tr :class="['shifts', {'err_shifts': rowHasErr}]">
      <td
        class="td_num_shift"
        :rowspan="readOnly ? 1 : 2"
        v-if="showIdx"
      >
          <div
              v-b-tooltip.hover :title="title || 'Смена №' + shift_idx"
              :class="['badge', 'badge_yellow', {'badge_min_circle': !readOnly}]"
          >
              {{ shift_idx }}
          </div>
      </td>

<!--     :rowspan="validate ? '2' : '1'" -->
    <td :colspan="showIdx ? 2 : 3"
        class="td_title_total_empl">
        Получилось сотрудников<span v-if="!showIdx"> в смене</span>:
    </td>
    <!-- TODO: if validate  -->
    <td
      v-for="(d, idx) in demands.slice(0, daysCount)"
      :key="idx"
      :class="[
        'td_total_empl',
        {'holy-start': holyStarts.includes(idx+1),
         'holy-end': holyEnds.includes(idx+1),
         err_demand: (totals[idx] !== d),
         'err_total_empl': errDays.includes(Number(idx)),
         }]"
    >
      <span v-if="totals[idx] < d">
          &#x25BC;
      </span>
      <span v-else-if="totals[idx] > d">
          &#x25B2;
      </span>
        {{ totals[idx] }}
    </td>
    <td class="td_total_shift"
        :rowspan="readOnly ? 1 : 2" :colspan="totalsColSpan"
    >{{ displayedTitle }}</td>
  </tr>

  <tr v-if="!readOnly" class="shifts">
      <td  :colspan="showIdx ? 2 : 3"
           class="td_title_need_empl">
          Требуется сотрудников<span v-if="!showIdx"> в смене</span>:</td>
      <td
        v-for="(d, idx) in demands.slice(0, daysCount)"
        :key="idx"
        :class="[
            'td_need_empl',
            {'holy-start': holyStarts.includes(idx+1),
             'holy-end': holyEnds.includes(idx+1),
             err_demand: (totals[idx] !== d),
             'err_need_empl': errDays.includes(Number(idx)),
             }]"
      >
          <input
              class="form-control form-control-sm"
              type="text"
              :value="d"
              v-on:change="demandChanged($event, idx)"
          />
      </td>
  </tr>
</tbody>

</template>

<script>
import {isWorkingAt} from "../../lib/shift_statuses";


export default {
  name: "PlanEditorDemandRow",
  props: ['daysCount', 'holyStarts', 'holyEnds', 'validate', 'readOnly',
      'demands', 'shift_idx', 'title', 'showIdx', 'employees', 'err_codes',
      'shiftHours', 'totalsColSpan', 'isMultiShift',
  ],
  components: {
  },
  data: function () {
    return {}
  },
  methods: {
    demandChanged(event, idx) {  // Изменение запроса на конкретный день
      const inp = event.target;
      let v = inp.value.replace(/[^0-9]+/g, '') || '0';
      // TODO: Может быть сразу оповещать пользователя о слишком больших значениях?
      this.$parent.$emit('change-demand',
          {'idx': idx, 'sh_idx': this.shift_idx, 'value': parseInt(v)});
    },
  },
  computed: {
    totals() {
        let result = Array.from({length: this.daysCount}, () => 0);
        for (let emp of this.employees) {
            for (let idx=0; idx < this.daysCount; idx++) {
                if (isWorkingAt(emp.shifts[idx].status, this.shift_idx)) {
                    result[idx] += 1;
                }
            }
        }
        return result;
    },
    errDays() {
        for (let code of this.err_codes) {
            if (typeof(code) === 'object' && code.day_demands) {
                // console.log('code.day_demands', code.day_demands);
                if (!code.sh_id || code.sh_id === this.shift_idx) {  // Либо ошибка общая для целого дня,
                    // либо соответствует смене для этого DemandRow
                    return code.day_demands;
                }
            }
        }
        return [];
    },
    rowHasErr() {
        return this.err_codes.includes('all_demands');
    },
    displayedTitle() {
        if (this.isMultiShift) {
            return this.title || 'Смена №' + this.shift_idx;
        } else {
            return '';
        }
    }
  },
}
</script>

