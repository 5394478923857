<template>
    <b-container>
        <div class="form-group row forbots">
            <label class="col-md-3 col-sm col-form-label" for="id_login">login</label>
            <div class="col-md-9 col-sm">
                <input type="text" name="login" placeholder="Логин" class="form-control" id="id_login"/>
            </div>
        </div>

        <AuthFormField v-bind:field="fields.email" @submit="sendRequest" />
        <AuthFormField v-bind:field="fields.password1" @submit="sendRequest" />
        <AuthFormField v-bind:field="fields.password2" @submit="sendRequest" />
        <AuthFormField v-if="hasPlanChanges" v-bind:field="fields.need_save" />
        <AuthFormField v-if="hasPlanChanges" v-bind:field="fields.plan_data" />

        <b-row
            style="margin-bottom: 1rem;"
            v-if="errors.length"
            class="error"
        >
            <b-col md="3"></b-col>
            <b-col md="9">
                <i class="fas fa-exclamation-circle"></i>
                <span> {{ errors.join('; ') }}</span>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="3"></b-col>
            <b-col md="9">
                <b-button class="btn btn_default" @click="sendRequest" :disabled="inProgress">
                    Зарегистрироваться
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <p class="help_text padd_top_15">
                    Регистрируясь в сервисе PLANIMUM, вы принимаете условия
                    <a target="_blank" href="/static/docs/oferta_planimum.pdf">договора-оферты</a>
                    и соглашаетесь на
                    <a target="_blank" href="/static/docs/policy_planimum.pdf">обработку персональных данных</a>.
                </p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {sendAuthRequest} from "../../model/AuthAPI";
import AuthFormField from './FormField';

export default {
    name: 'AuthFormSignUp',
    components: {AuthFormField},
    props: ['hasPlanChanges', 'planData'],
    data() {
        return {
            inProgress: false,
            errors: [],
            fields: {
                email: {
                    name: 'email',
                    label: 'E-mail',
                    placeholder: 'E-mail адрес',
                    inputType: 'email',
                    errors: [],
                    value: '',
                },
                password1: {
                    name: 'password1',
                    label: 'Пароль',
                    inputType: 'password',
                    errors: [],
                    value: '',
                },
                password2: {
                    name: 'password2',
                    label: 'Пароль (ещё раз)',
                    inputType: 'password',
                    errors: [],
                    value: '',
                },
                need_save: {
                    name: 'need_save',
                    label: 'Сохранить составленный план в кабинет',
                    inputType: 'checkbox',
                    errors: [],
                    value: true,
                },
                plan_data: {
                    name: 'plan_data',
                    inputType: 'hidden',
                    value: this.planData,
                },
            }
        }
    },
    methods: {
        sendRequest() {
            this.inProgress = true;
            // FIXME: disable signup button
            let successCallback = this.$emit.bind(this,  // FIXME: Учесть, что аккаунт может быть заведён, а план по каким-то причинам не создан
                'force-redirect',
                '/accounts/confirm-email/'
            );
            sendAuthRequest(
                '/accounts/signup/',
                this,
                successCallback,
                'Не удалось провести регистрацию. Попробуйте чуть позже.'
            );
        }
    }
}
</script>

