// import Cookie from "js-cookie";
import axios from "axios";

import {unixTime} from '../lib/dates';

let QUEUE = [];  // Ещё не отосланные события
let DEBOUNCED = false;  // Флажок о том, что отправку из одного события тоже уже можно делать
let TO_SKIP = 0;  // Сколько пятисекундных итераций нужно пропустить прежде чем отослать
let NEXT_SKIP_INTERVAL = 1;  // Сколько пропусков назначить в случае проблем с отправкой
let LOOP_STARTED = false;  // Начата ли регулярная отправка событий

const TRACK_URL = '/selfmon/track_events/';  // FIXME: Получать такие урлы из Django

// Отслеживение событий

export function trackEvent(action, args) {
    QUEUE.push({'action': action, 'args': args, 'dt': unixTime()});
}

// Модалки
let CURRENT_MODAL = {};

export let modalTracker = {
    open(modal_id) {
        // Не создаём здесь новый объект, так как некоторые поля могли назначаться до фактического открытия модалки.
        CURRENT_MODAL['modal_id'] = modal_id;
        CURRENT_MODAL['open_ts'] = unixTime();
    },
    close(modalId) {
        // if (CURRENT_MODAL['modal_id'] === undefined) {
        //     // TODO: Разобраться, когда это происходит
        //     CURRENT_MODAL = {};
        //     return;
        // }
        let dt = CURRENT_MODAL['open_ts']
        delete CURRENT_MODAL['open_ts']

        let modal_id = CURRENT_MODAL['modal_id']
        if (modal_id === undefined) {
            modal_id = modalId
        } else {
            delete CURRENT_MODAL['modal_id']
        }
        let action = 'modal:' + modal_id;

        CURRENT_MODAL['duration'] = unixTime() - dt;
        QUEUE.push({'action': action, 'args': CURRENT_MODAL, 'dt': dt});
        CURRENT_MODAL = {};
    },
    setField(field, val) {
        CURRENT_MODAL[field] = val;
    }
}

// Отправка событий на бэкенд

function sendBatch(batch) {
    let data = {'events': batch, 'dt': unixTime()}; // Текущее время нужно для синхронизации с серверным временем
    let request = {
        url: TRACK_URL,
        contentType: 'application/json; charset=utf-8',
        method: 'POST',
        timeout: 2000,
        headers: {
            // "X-CSRFToken": Cookie.get('csrftoken'),
            "X-Requested-With": "XMLHttpRequest",
        },
        data: data,
        dataType: "json",
    };
    axios(request
    ).then(function (response) {  // eslint-disable-line
        TO_SKIP = 0;
        NEXT_SKIP_INTERVAL = 1;
        DEBOUNCED = false;
    }).catch(function (error) {
        console.error('send events failed', error && error.response ? [error.response.status, error.response.statusText] : error )
        TO_SKIP = NEXT_SKIP_INTERVAL;
        if (NEXT_SKIP_INTERVAL <= 10) {
            NEXT_SKIP_INTERVAL += 1;
        }
        QUEUE.push(...batch);  // TODO: Соломки подстелить от чрезмерного разбухания очереди
    });
    // console.log("Send", batch);
}

export function flushEvents() {
    // Экстренное отправление всех сообщений
    // Вызывается перед закрытием страницы
    if (CURRENT_MODAL.modal_id) {
        modalTracker.close();
    }

    let data = JSON.stringify({'events': QUEUE.splice(0), 'dt': unixTime()});
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
    if (window.navigator && window.navigator.sendBeacon) {
        window.navigator.sendBeacon(TRACK_URL, data);
    } else {
        let xhr = new XMLHttpRequest();
        xhr.open("POST", TRACK_URL, false); // `false` means synchronous
        xhr.timeout = 300;  // Не хочется заметной задержки перед загрузкой следующей страницы.
        // Обычно запрос за это время уже выполняется. Как минимум, все данные уже будут отправлены на сервер.
        xhr.send(data);
    }
}

export function startSendingLoop() {
    window.trackEvent = trackEvent;

    if (LOOP_STARTED) {
        return
    }
    setInterval(function() {
        if (TO_SKIP > 0) {
            TO_SKIP -= 1;
            return;
        }
        if (QUEUE.length === 0) {
            return;
        }
        if (QUEUE.length === 1 && !DEBOUNCED) {
            DEBOUNCED = true;
            return;
        }
        let batch = QUEUE.splice(0);
        sendBatch(batch);
    },
        5000);
    LOOP_STARTED = true;
}
