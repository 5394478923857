<template>
    <div class="form-group row">
        <label class="col-md-4 col-5">{{ sel.title }}</label>
        <div class="col-md-4 col-3">
            <b-form-input
                size="sm"
                @keyup="batchFieldChanged"
                :value="isNaN(sel.val) ? '' : sel.val"
            >
            </b-form-input>
        </div>
        <div class="col-md-2 col-2">
            <b-button
                :disabled="sel.applied || isNaN(sel.val)"
                variant="btn btn-sm mb-2"
                @click="$parent.$emit('apply-cell', {cell: sel, sh_idx: sh_idx})"
                class="btn_yellow"
            >
                Ок
            </b-button>
        </div>
        <div class="col-md-2 col-2">
            <!-- Success slot -->
            <i class="fas fa-check text-success" v-if="sel.applied"></i>
            <i class="fas fa-question text-muted"
               v-else-if="sel.rewrited"
               v-b-tooltip
               :title="'На ' + sel.rewrited.title + ' назначено ' + sel.rewrited.value + '. Применить ' + sel.val + ' на ' + sel.title.toLowerCase() + '?'"
            ></i>
        </div>
    </div>
</template>

<script>
export default {
    name: "WeeklyDemandCell",
    props: ['sel', 'sh_idx'],
    methods: {
        batchFieldChanged(event) {
          if (event.type === 'keyup' && event.key === 'Enter') {
              this.$parent.$emit('apply-cell', {cell: this.sel, sh_idx: this.sh_idx});
              return;
          }

          let inputed = event.target.value;
          inputed = parseInt(inputed.replace(/[^0-9]+/g, ''));
          if (inputed !== this.sel.val) {
            this.sel.val = inputed;
            this.sel.applied = false;
          }
        },
    }
}
</script>

<style scoped>

</style>
