var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',{staticClass:"tbody_shifts"},[_c('tr',{class:['shifts', {'err_shifts': _vm.rowHasErr}]},[(_vm.showIdx)?_c('td',{staticClass:"td_num_shift",attrs:{"rowspan":_vm.readOnly ? 1 : 2}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:['badge', 'badge_yellow', {'badge_min_circle': !_vm.readOnly}],attrs:{"title":_vm.title || 'Смена №' + _vm.shift_idx}},[_vm._v("\n              "+_vm._s(_vm.shift_idx)+"\n          ")])]):_vm._e(),_c('td',{staticClass:"td_title_total_empl",attrs:{"colspan":_vm.showIdx ? 2 : 3}},[_vm._v("\n        Получилось сотрудников"),(!_vm.showIdx)?_c('span',[_vm._v(" в смене")]):_vm._e(),_vm._v(":\n    ")]),_vm._l((_vm.demands.slice(0, _vm.daysCount)),function(d,idx){return _c('td',{key:idx,class:[
        'td_total_empl',
        {'holy-start': _vm.holyStarts.includes(idx+1),
         'holy-end': _vm.holyEnds.includes(idx+1),
         err_demand: (_vm.totals[idx] !== d),
         'err_total_empl': _vm.errDays.includes(Number(idx)),
         }]},[(_vm.totals[idx] < d)?_c('span',[_vm._v("\n          ▼\n      ")]):(_vm.totals[idx] > d)?_c('span',[_vm._v("\n          ▲\n      ")]):_vm._e(),_vm._v("\n        "+_vm._s(_vm.totals[idx])+"\n    ")])}),_c('td',{staticClass:"td_total_shift",attrs:{"rowspan":_vm.readOnly ? 1 : 2,"colspan":_vm.totalsColSpan}},[_vm._v(_vm._s(_vm.displayedTitle))])],2),(!_vm.readOnly)?_c('tr',{staticClass:"shifts"},[_c('td',{staticClass:"td_title_need_empl",attrs:{"colspan":_vm.showIdx ? 2 : 3}},[_vm._v("\n          Требуется сотрудников"),(!_vm.showIdx)?_c('span',[_vm._v(" в смене")]):_vm._e(),_vm._v(":")]),_vm._l((_vm.demands.slice(0, _vm.daysCount)),function(d,idx){return _c('td',{key:idx,class:[
            'td_need_empl',
            {'holy-start': _vm.holyStarts.includes(idx+1),
             'holy-end': _vm.holyEnds.includes(idx+1),
             err_demand: (_vm.totals[idx] !== d),
             'err_need_empl': _vm.errDays.includes(Number(idx)),
             }]},[_c('input',{staticClass:"form-control form-control-sm",attrs:{"type":"text"},domProps:{"value":d},on:{"change":function($event){return _vm.demandChanged($event, idx)}}})])})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }