<template>
    <b-form-group
        :label="label"
        :description="description"
    >
        <div class="cycles_working_days">
            <b-row>
                <b-col sm="1" md="1">от</b-col>
                <b-col sm="2" md="2">
                    <b-form-input
                        v-model="min_work"
                        id=""
                        type="text"
                        size="sm"
                    ></b-form-input>
                </b-col>
                <b-col sm="1" md="1">до</b-col>
                <b-col sm="2" md="2">
                    <b-form-input
                        v-model="max_work"
                        id=""
                        type="text"
                        size="sm"
                    ></b-form-input>
                </b-col>
                <b-col sm="6" md="6">рабочих дней подряд</b-col>
            </b-row>
        </div>
        <div
                class="error_text"
                v-for="(err, idx) in work_err"
                v-bind:key="idx"
        >
            {{ err }}
        </div>

        <div class="cycles_rest_days">
            <b-row>
                <b-col sm="1" md="1">от</b-col>
                <b-col sm="2" md="2">
                    <b-form-input
                        v-model="min_free"
                        id=""
                        type="text"
                        size="sm"
                    ></b-form-input>
                </b-col>
                <b-col sm="1" md="1">до</b-col>
                <b-col sm="2" md="2">
                    <b-form-input
                        v-model="max_free"
                        id=""
                        type="text"
                        size="sm"
                    ></b-form-input>
                </b-col>
                <b-col sm="6" md="6">выходных дней подряд</b-col>
            </b-row>
        </div>
        <div
            class="error_text"
            v-for="(err, idx) in free_err"
            v-bind:key="idx"
        >
            {{ err }}
        </div>

    </b-form-group>
</template>

<script>
import {setIfChanged} from "../../lib/objects";

export default {
    name: 'WorkPatternWidget',
    props: ['stored', 'daysCount', 'label', 'description'],
    data() {
        return {
            'min_work': null,
            'max_work': null,
            'work_err': [],

            'min_free': null,
            'max_free': null,
            'free_err': [],
        }
    },
    methods: {
        loadStored() {
            this.min_work = this.stored.min_work ? String(this.stored.min_work) : null;
            this.max_work = this.stored.max_work ? String(this.stored.max_work) : null;
            this.work_err.splice(0);
            this.min_free = this.stored.min_free ? String(this.stored.min_free) : null;
            this.max_free = this.stored.max_free ? String(this.stored.max_free) : null;
            this.free_err.splice(0);
        },
        cleanInput() {  // Возвращает, все ли введённые данные валидны
            this.work_err.splice(0);
            this.free_err.splice(0);

            this.min_work = this.cleanField(this.min_work, this.work_err);
            this.max_work = this.cleanField(this.max_work, this.work_err);
            this.min_free = this.cleanField(this.min_free, this.free_err);
            this.max_free = this.cleanField(this.max_free, this.free_err);

            if (typeof(this.min_work) === 'number' && typeof(this.max_work) === 'number' && this.min_work > this.max_work) {
                this.work_err.push('"от" не может быть больше, чем "до"');
            }
            if (typeof(this.min_free) === 'number' && typeof(this.max_free) === 'number' && this.min_free > this.max_free) {
                this.free_err.push('"от" не может быть больше, чем "до"');
            }
            return this.work_err.length === 0 && this.free_err.length === 0;
        },
        cleanField(inp_value, err_list) {
            if (typeof(inp_value) === 'string') {  // это значит, что были какие-то изменения
                inp_value = inp_value.replace(/\s/g, '');
                if (inp_value) {
                    let num = Number(inp_value);
                    if (isNaN(num)) {
                        err_list.push('"' + inp_value + '" - должно быть либо числом, либо пустым');
                    } else if (num < 1) {
                        err_list.push('"' + inp_value +'" - должно быть числом от единицы и больше');
                    } else if (num > this.daysCount) {
                        err_list.push('"' + inp_value + '" - число за пределами месяца');
                    } else {
                        return num;
                    }
                } else {
                   return null;
                }
            }
            return inp_value;
        },
        storeInput() {  // Возвращает bool, есть ли хоть какие-то изменения
            let hasChanges = false;
            // console.log(this.stored.entries());
            hasChanges |= setIfChanged(this.stored, 'min_work', this.min_work);
            hasChanges |= setIfChanged(this.stored, 'max_work', this.max_work);
            hasChanges |= setIfChanged(this.stored, 'min_free', this.min_free);
            hasChanges |= setIfChanged(this.stored, 'max_free', this.max_free);
            return hasChanges;
        }
    },
}
</script>
