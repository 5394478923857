<template>
    <b-nav-item
        class="dashed_link"
        href="#global-work-pattern"
        @click="showForm"
    >
        <i class="fas fa-border-all"></i>&nbsp;&nbsp;
        <span>Периодичность работы</span>

        <b-modal
            id="global-work-pattern"
            title="Периодичность работы"
            size="md"
            @ok="onOk"
            class="empl_form"
        >
            <template slot="default">
                <p class="help_text">
                    <i class="fas fa-exclamation-circle"></i>
                    Применяется ко всем сотрудникам.
                    Индивидуальные настройки можно указать в Данных сотрудника.
                </p>
                <b-form class="empl_form">
                    <WorkPatternWidget
                        ref="work_pattern"
                        :stored="empPattern"
                        :label="''"
                        :days-count="daysCount"
                    />
                </b-form>
            </template>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button
                    class="btn_default"
                    @click="ok"
                >
                    Ок
                </b-button>
                <b-button
                    variant="light"
                    @click="cancel()"
                >
                    Закрыть
                </b-button>
            </template>
        </b-modal>
    </b-nav-item>
</template>

<script>
import {setLocationHash} from '../../lib/nav'
import WorkPatternWidget from "./WorkPatternWidget";

export default {
    name: "WorkPatternGlobal",
    components: {WorkPatternWidget},
    props: ['empPattern', 'daysCount'],
    methods: {
        onOk(event) {
            let hasErrors = false;
            let hasChanges = false;

            if (!this.$refs.work_pattern.cleanInput()) {
                hasErrors = true;
            }

            if (hasErrors) {
                event.preventDefault();
            } else {
                hasChanges = this.$refs.work_pattern.storeInput();
            }

            if (hasChanges) {
                this.$emit('set-unsaved');
            }
        },
        showForm(event) {
            event.preventDefault();
            setLocationHash('#global-work-pattern');
            this.$bvModal.show('global-work-pattern');
            let self = this;
            this.$nextTick(() => self.$refs.work_pattern.loadStored());
        }
    }
}
</script>

<style scoped>

</style>
