<template>
<span>
    <b-modal id="authenticate" hide-header title="Аутентификация">
            <b-tabs content-class="mt-2" fill>
                <b-tab title="Войти" :active="authTab === 'login'">
                    <AuthFormLogin
                        v-bind:hasPlanChanges="hasPlanChanges"
                        v-bind:planData="planData"
                        @force-reload="$emit('force-reload')"
                        @force-redirect="$emit('force-redirect', $event)"
                        @after-login-save-error="afterLoginSaveError"
                    />
                </b-tab>
                <b-tab title="Зарегистрироваться" :active="authTab === 'sign-up'">
                    <AuthFormSignUp
                        v-bind:hasPlanChanges="hasPlanChanges"
                        v-bind:planData="planData"
                        @force-reload="$emit('force-reload')"
                        @force-redirect="$emit('force-redirect', $event)"
                    />
                </b-tab>
            </b-tabs>
        <template v-slot:modal-footer="{ close }">
            <b-button
                variant="light"
                size=""
                @click="close()"
            >
                Закрыть
            </b-button>
        </template>
    </b-modal>

  <form ref="afterLoginForm" method="POST" action="/my/schedule/create/">
    <input name="plan_data" type="hidden" :value="planData" />
    <input name="cause" type="hidden" :value="saveError" />
    <input name="csrfmiddlewaretoken" type="hidden" :value="csrftoken" />
  </form>
</span>
</template>

<script>
import axios from 'axios';
import Cookie from 'js-cookie';
import AuthFormLogin from "./FormLogin";
import AuthFormSignUp from "./FormSignUp";

export default {
    name: "AuthDispatch",
    data() {
        return {
            authTab: 'sign-up',
            hasPlanChanges: false,
            planData: '',
            saveError: '',
            csrftoken: '',
        }
    },
    components: {
        AuthFormLogin,
        AuthFormSignUp,
    },
    methods: {
        callFunction(href, hasChanges, planData) {
            this.hasPlanChanges = hasChanges;
            this.planData = hasChanges ? JSON.stringify(planData) : '';
            if (href === '#logout') {
                this.logout(hasChanges);
            } else if (href === '#login' || href === '#sign-up') {
                this.showAuthTab(href.slice(1));
            } else {
                console.error('unknown call', href, hasChanges, arguments);
            }
        },
        logout(hasChanges) {
            let question = 'Действительно хотите разлогиниться?';
            if (hasChanges) {
                question += '\nВведённые изменения не сохранятся';
            }
            if (confirm(question)) {
                let request = {
                    url: '/accounts/logout/', // FIXME: Получать такие урлы из Django
                    contentType: 'application/json; charset=utf-8',
                    method: 'POST',
                    headers: {"X-CSRFToken": Cookie.get('csrftoken')},
                    data: {},
                    dataType: "json"
                };
                let emit = this.$emit.bind(this);
                axios(request).then(function () {
                    emit('force-reload');
                });
                // .catch();  TODO: сообщение об ошибке
            }
        },
        showAuthTab(tabName) {
            this.authTab = tabName;
            window.location.hash = 'authenticate';
            this.$bvModal.show('authenticate');
        },
        afterLoginSaveError(response) {
          this.saveError = JSON.stringify(response);
          this.csrftoken = Cookie.get('csrftoken');
          let form = this.$refs.afterLoginForm;
          // отключаем проверку ухода со страницы
          this.$emit('set-ignore-changes');

          this.$nextTick(() => {
            form.submit();
          });
        }
    }
}
</script>

