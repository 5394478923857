<template>
<b-card-text>
    <b-nav vertical>
        <b-nav-item
            v-for="chapter in list"
            v-bind:key="chapter.key"
            :href="'#tutorial-' + chapter.key"
            @click="showChapter($event, chapter.key, chapter.title)"
            class="dashed_link"
        >
            <i class="fas fa-question-circle"></i>&nbsp;<span>{{ chapter.title }}</span>
        </b-nav-item>
    </b-nav>

    <b-modal
        id="modal_tutorial"
        size="lg"
        scrollable
        :title="title || 'Справка'"
    >
        <div v-if="current" v-html="current"></div>
        <div v-else-if="error === 404" class="pl-status-message-error">
            Такой страницы нет
        </div>
        <div v-else-if="error === 'no_response'" class="pl-status-message-error">
            Не удалось загрузить страницу. Проверьте соединение и попробуйте ещё раз.
        </div>
        <div v-else-if="error" class="pl-status-message-error">
            Не удалось загрузить страницу. Попробуйте чуть позже.
        </div>
        <div v-else class="pl-status-message-loading">
            Загружаем...
        </div>

        <template v-slot:modal-footer="{ close }">
            <b-button
                    class="btn_default"
                    @click="close()"
            >
                Понятно
            </b-button>
        </template>
    </b-modal>
</b-card-text>
</template>

<script>
import model from '../model/Tutorial'
import {modalTracker} from "../model/EventLog";

export default {
    name: "Tutorial",
    data() {
      return {
          title: null,
          ...model.newState(), // list, loaded, current, error
      }
    },
    methods: {
        showChapter(event, key, title) {
            this.title = title;
            model.loadChapter(this, key);
            this.$bvModal.show('modal_tutorial');
            modalTracker.setField('key', key);
        }
    }
}
</script>

