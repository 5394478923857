<template>
  <div class="daily_list" role="tablist">
    <b-card no-body
        v-for="d of dayList"
        :key="d.day"
    >
      <b-card-header header-tag="header" class="p-1 text-left" role="tab">
        <b-link
            class="dashed_link_norm"
            v-b-toggle="'day-' + d.day">
          {{ d.title }}
        </b-link>
      </b-card-header>
      <b-collapse
          :id="'day-' + d.day"
          :visible="d.day === today || d.day === tomorrow"
          @hidden="track('day_list:close', d.day)"
          @shown="track('day_list:open', d.day)"
          role="tabpanel">
        <b-card-body>

          <b-card-text v-if="d.emps">
            Работают:
            <ol>
              <li
                  v-for="(name, idx) in d.emps"
                  :key="idx"
              >
                {{ name }}
              </li>
            </ol>
          </b-card-text>
          <b-card-text v-else-if="d.shifts">
            <span
                v-for="(sh, idx) in d.shifts"
                :key="idx"
            >
              {{ sh.title }}
              <ol>
                <li
                    v-for="(name, idx) in sh.emps"
                    :key="idx"
                >
                  {{ name }}
                </li>
              </ol>
            </span>

          </b-card-text>

        </b-card-body>
      </b-collapse>

    </b-card>
  </div>
</template>

<script>
import {newData} from '../model/PlanInit';
import months from "../model/PlanMonth";
import hoursMan from "../model/HoursMan";
import emptable from "../model/PlanEmpTable";
import shift from "../model/PlanShifts";
import {WEEKDAYS} from "../lib/dates";
import {isWorkingDay, isWorkingAt, hourShiftName} from "../lib/shift_statuses";
import {trackEvent} from "../model/EventLog";

export default {
    name: "DailyList",
    data() {
      let init = newData();
      // Предполагаем, что сейчас может быть только на странице display
      let monthInfo = months.newState(init, null);

      let today = monthInfo.month === monthInfo.currentMonth ? monthInfo.currentDay : null;

      let tomorrow = null;
      if (today && today !== monthInfo.endDay) {
        tomorrow = today + 1;
      } else if (monthInfo.month === monthInfo.nextMonth && monthInfo.currentDay === monthInfo.endDay) {
        tomorrow = 1;
      }
      return {
        // в каментах указаны ключи, которые добавляет каждая из моделей
        ...hoursMan.newState(init, null),  // withHoursMan, usedHourIntervals
        monthInfo, // month, currentMonth, daysCount, weekends, holyAdd, holyRemove, firstWeekDay
        ...emptable.newState(init, null, monthInfo.daysCount),  // employees, last_emp_id
        ...shift.newState(init, monthInfo.daysCount),  // shifts, shift_seq_rules
        today,
        tomorrow,
      };
    },
    computed: {
      dayList() {
        let info = this.monthInfo;
        let monthName = months.key2human(info.month);
        let arr = new Array(info.daysCount);
        for (let idx of arr.keys()) {
          let wd = (info.firstWeekDay + idx) % 7;
          let day = idx + 1;
          let title = day + ' ' + monthName + ', ' + WEEKDAYS[wd].full;
          if (day === this.today) {
            title += ' (сегодня)';
          } else if (day === this.tomorrow) {
            title += ' (завтра)';
          }
          arr[idx] = { day, title };
          if (this.withHoursMan) {
            arr[idx].shifts = this.hourShiftList(idx);
          } else if (this.shifts.length > 1) {
            arr[idx].shifts = this.shiftList(idx);
          } else {
            arr[idx].emps = this.empList(idx);
          }
        }
        return arr;
      },
    },
    methods: {
      empList(dayIdx, shiftIdx, withHours) {
        // dayIdx - с нуля, shiftIdx - с единицы
        let check = shiftIdx ? function (status) {
          return isWorkingAt(status, shiftIdx);
        } : isWorkingDay

        let arr = [];
        for (let emp_idx in this.employees) {
          let emp = this.employees[emp_idx];
          let status = emp.shifts[dayIdx].status;
          if (check(status)) {
            let name = emp.name || 'Сотрудник №' + (emp_idx+1);
            if (withHours) {
              arr.push({name: name, start: status.start, end: status.end});
            } else {
              arr.push(name);
            }
          }
        }
        return arr;
      },
      shiftList(dayIdx) {
        let self = this;
        return this.shifts.map(function (shift, sh_idx) {
          return {
            title: shift.title || 'Смена ' + (sh_idx+1),
            emps: self.empList(dayIdx, (sh_idx+1)),
          }
        });
      },
      hourShiftList(dayIdx) {
        let shifts = new Map();
        for (let emp of this.empList(dayIdx, null, true)) {
          let sh_name = hourShiftName(emp);
          if (!shifts.has(sh_name)) {
            shifts.set(sh_name, []);
          }
          shifts.get(sh_name).push(emp.name);
        }
        return [...shifts.entries()].sort().map(function (sh) {
          return { title: sh[0], emps: sh[1] };
        });
      },
      track(action, day) {
        trackEvent(action, {'day': day});
      },
    }

}
</script>
