<template>
    <b-nav-item
        class="dashed_link"
        href="#shift-sequence"
        v-b-modal="'shift-sequence'"
    >
<!--        <i class="fas fa-tasks"></i>&nbsp;&nbsp;-->
        <i class="fas fa-grip-horizontal"></i>&nbsp;&nbsp;
        <span>Чередование смен</span>

        <b-modal
            id="shift-sequence"
            title="Чередование смен"
            size="lg"
            @ok="onOk"
            @show="onShow"
            class="empl_form"
        >
            <template slot="default">
                <p class="help_text">
                    <i class="fas fa-exclamation-circle"> </i>
                    Здесь задаются правила, какие смены после каких могут идти.
                    <span v-if="shifts.length <= 1"><br/>Для этого нужно хотя бы две смены.</span>
                </p>
                <p class="help_text">
                    <a target="_blank" href="/tutorial/shift_seq_rules/#content-start">Как пользоваться...</a>
                </p>

                <b-form-group
                    class="shift_sequence_form"
                >
                    <div class="row_add_button">
                        <b-row>
                            <b-col>
                                <b-button
                                    :disabled="shifts.length <= 1"
                                    class="btn-sm btn_yellow"
                                    @click="addRule"
                                >
                                    <i class="fas fa-plus"></i>
                                    Добавить правило
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>

                    <ShiftSeqRule
                        v-for="(sel, idx) in selected"
                        v-bind:key="idx"
                        :shift_options="shift_options"
                        :selected="sel"
                        @remove-me="selected.splice(idx, 1)"
                    />
                </b-form-group>
                <b-form-invalid-feedback
                        v-if="errMsg"
                        class="error_text"
                        :state="!errMsg">
                    {{ errMsg }}
                </b-form-invalid-feedback>
            </template>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button
                    :disabled="shifts.length <= 1"
                    class="btn_default"
                    @click="ok"
                >
                    Ок
                </b-button>
                <b-button
                    variant="light"
                    @click="cancel()"
                >
                    Отмена
                </b-button>
            </template>
        </b-modal>
    </b-nav-item>
</template>

<script>
import PlanShifts from "../../model/PlanShifts";
import ShiftSeqRule from "./ShiftSeqRule";

export default {
    name: "ShiftSequence",
    components: {ShiftSeqRule},
    props: ['shifts', 'shift_seq_rules'],
    data() {
        return {
            selected: [],
            shift_options: [],
            errMsg: '',
        }
    },
    methods: {
        newSelector() {
            return {case: null, base: null, rule: null, others: [], errMsg: ''}
        },
        selectorFromRule(rule) {
            return {
                case: rule.case,
                base: rule.base,
                rule: rule.rule,
                others: Array.from(rule.others),
                errMsg: '',
            }
        },
        makeShiftOptions(shifts) {
            return shifts.map((sh, idx) => {
                return {
                    value: idx+1,
                    text: sh.title ? (idx+1) + ' - ' + sh.title : 'Смена №' + (idx+1)}
            });
        },
        addRule() {
            this.selected.push(this.newSelector());
        },

        /// Управление окном
        onShow() {
            this.errMsg = '';
            this.shift_options = this.makeShiftOptions(this.shifts);
            this.selected.splice(0, this.selected.length, ...this.shift_seq_rules.map(this.selectorFromRule))
        },
        onOk(event) {
            this.errMsg = '';
            let hasMissing = false;
            for (let sel of this.selected) {
                if (sel.case === null || sel.base === null || sel.rule === null ||
                    (PlanShifts.WITH_OTHERS.includes(sel.rule) && sel.others.length === 0)) {
                    sel.errMsg = 'Дозаполните или удалите это правило';
                    hasMissing = true;
                } else {
                    sel.errMsg = '';
                }
            }
            // TODO: Противоречия тоже проверять
            if (hasMissing) {
                event.preventDefault();
                this.errMsg = 'Все поля должны быть заполнены';
            } else {
                this.$emit('set-shift-seq-rules', this.selected);
            }
        }
    }
}
</script>

