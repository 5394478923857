import {dayStatus, isWorkingDay} from "../lib/shift_statuses";


export default {
    newState(init, wizardFields) {
        let withHoursMan = false;
        if (wizardFields && wizardFields.withHoursMan !== undefined) {
            withHoursMan = wizardFields.withHoursMan;
        } else if (init && init.features) {
            withHoursMan = init.features.hours_man;
        }

        let usedHourIntervals = [];
        if (init) {
            usedHourIntervals = this.usedIntervals(init.emptable);
        }

        return {
            withHoursMan,
            usedHourIntervals,
        }
    },
    makeSlices(employees, daysCount) {
        // Находим в сутках часы, в которых происходит смена состава сотрудников
        let slices = Array.from({length: daysCount},() => [0]);
        for (let emp of employees) {
            for (let [idx, cell] of emp.shifts.entries()) {
                if (typeof(cell.status) === 'object') {
                    let start = cell.status.start;
                    if (start && !slices[idx].includes(start)) {
                        slices[idx].push(start);
                    }
                    let end = cell.status.end;
                    if (end && end < 24) {
                        if (end <= start) {
                            idx++;
                            if (idx >= daysCount) {
                                continue;
                            }
                        }
                        if (!slices[idx].includes(end)) {
                            slices[idx].push(end);
                        }
                    }
                }

            }
        }
        for (let s of slices) {
            s.sort((a,b) => a-b);
        }
        return slices;
    },
    makeStatuses(hourSlices, shifts) {
        let row = Array.from({length: hourSlices.length}, () => null);
        let prevStatus = null;  // не null только если заезжает за следующую ячейку

        for (let [idx, slice] of hourSlices.entries()) {
            let status = shifts[idx].status;
            if (typeof(status) === 'object' && status.start !== undefined) {
                let workStatus = {status: dayStatus(status)};
                let substatuses = slice.map((hour) => {
                    if (hour >= status.start) {
                        if (status.end <= status.start || status.end > hour) {
                            return workStatus;
                        } else {
                            return {status: 'free'};
                        }
                    } else {
                        return {status: 'free'};
                    }
                });
                if (prevStatus !== null) {
                    let workStatus = {status: dayStatus(prevStatus)};
                    for (let [sub_idx, hour] of slice.entries()) {
                        if (hour < prevStatus.end) {
                            substatuses[sub_idx] = workStatus;
                        } else {
                            break;
                        }
                    }
                }
                row[idx] = substatuses;
            } else if (prevStatus !== null) {
                let workStatus = {status: dayStatus(prevStatus)};
                let freeStatus = {status: dayStatus(status)};
                row[idx] = slice.map((hour) => {
                    return hour < prevStatus.end ?  workStatus :  freeStatus;
                });
            }
            if (status.start >= status.end) {
                prevStatus = status;
            } else {
                prevStatus = null;
            }
        }
        return row;
    },
    slicedTotals(hourSlices, employees) {
        let row = hourSlices.map((slice) => {
            return Array.from({length: slice.length}, () => 0);
        });
        for (let emp of employees) {
            for (let [day_idx, cell] of emp.shifts.entries()) {
                let status = cell.status;
                if (typeof(status) !== 'object' || status.start === undefined || !isWorkingDay(status)) {
                    continue;
                }
                let start = status.start;
                let end = status.end;
                let hours = hourSlices[day_idx];
                let slice = row[day_idx];
                for (let [s_idx, hour] of hours.entries()) {
                    if (hour < start) {
                        continue;
                    }
                    if (end <= start || end > hour) {
                        slice[s_idx] += 1;
                    }
                }
                if (end <= start && day_idx+1 < row.length) {
                    let next_slice = row[day_idx+1];
                    for (let [s_idx, hour] of hourSlices[day_idx+1].entries()) {
                        if (hour < end) {
                            next_slice[s_idx] += 1;
                        }
                    }
                }
            }
        }
        return row;
    },
    // Использованные интервалы
    usedIntervals(emptable) {
        let intervals = [];
        for (let row of emptable) {
            for (let status of row) {
                if (typeof(status) === 'object' || status.start !== undefined) {
                    if (!intervals.some((sub) => sub[0] === status.start && sub[1] === status.end)) {  // intervals.includes
                        intervals.push([status.start, status.end])
                    }
                }
            }
        }
        this.sortHourIntervals(intervals);
        return intervals;
    },
    addToIntervalHistory(state, interval) {
        if (!state.usedHourIntervals.some((sub) => sub[0] === interval[0] && sub[1] === interval[1])) { // includes(interval)
            state.usedHourIntervals.push(interval);
            this.sortHourIntervals(state.usedHourIntervals);
        }
    },
    sortHourIntervals(intervals) {
        intervals.sort((a, b) => {
            let diff = a[0] - b[0];
            if (diff === 0) {
                return a[1] - b[1];
            } else {
                return diff;
            }
        });
    }
}
