export function parseIntDefault(str, def) {
    // Если строка не заполнена, возвращает дефолтное значение
    if (typeof(str) === "number") {
        return str;  // Уже готовое значение
    }
    if (!str) {
        return def;  // Не заполненное значение
    }
    str = str.replace(/[^0-9]+/g, '');
    if (str === '') {
        return def;    // Не заполненное значение
    }
    return parseInt(str);
}
