<template>
<div class="row_setting">
    <b-row>
        <b-col lg="2">
            <b-form-select
                size="sm"
                v-model="selected.case"
                :options="case_options"
            ></b-form-select>
        </b-col>

        <b-col lg="3">
            <b-form-select
                size="sm"
                v-model="selected.base"
                :options="base_shift_options"
            ></b-form-select>
        </b-col>

        <b-col lg="3">
            <b-form-select
                size="sm"
                v-model="selected.rule"
                :options="rule_options"
            ></b-form-select>
        </b-col>

        <b-col
            v-if="show_others"
            lg="3">
            <b-button
                :id="'shift-seq-rule-others-selector-' + uid"
                size="sm"
                class="btn-block btn_block_white"
            >
                <span class="align"> {{ [...selected.others].sort().join(',') || 'выбрать смены...' }}</span>
                &nbsp;
                <i class="fas fa-caret-down"></i>
            </b-button>
            <b-popover
                :target="'#shift-seq-rule-others-selector-' + uid"
                placement="bottomleft"
                triggers="click blur"
            >
                <b-checkbox-group
                    v-model="selected.others"
                    :options="others_shift_options"
                    :stacked="true"
                />
            </b-popover>
        </b-col>

        <b-col :lg="show_others ? 1 : 4" class="del_button">
            <b-button
                size="sm"
                class="btn_red"
                @click="onRemove"
            >
                <i class="far fa-trash-alt"></i>
            </b-button>
        </b-col>

    </b-row>

    <b-form-invalid-feedback
        v-if="selected.errMsg"
        class="error_text"
        :state="!selected.errMsg">
        {{ selected.errMsg }}
    </b-form-invalid-feedback>
</div>
</template>

<script>
import PlanShifts from "../../model/PlanShifts";
let last_id = 0;


export default {
    name: 'ShiftSeqRule',
    props: ['selected', 'shift_options'],
    data() {
        return {
            uid: ++last_id,
        }
    },
    computed: {
        case_options() {
            return PlanShifts.CASE_OPTIONS
        },
        rule_options() {
            if (this.selected.base === 0) {
                // Правила для выходного задаются только рядом с конкретными сменами
                return PlanShifts.RULE_OPTIONS.filter((opt) => PlanShifts.WITH_OTHERS.includes(opt.value));
            } else {
                return PlanShifts.RULE_OPTIONS;
            }

        },
        show_others() {
            return PlanShifts.WITH_OTHERS.includes(this.selected.rule);
        },
        base_shift_options() {
            if (this.selected.rule === null || this.show_others) {
                // Правила для выходного задаются только рядом с конкретными сменами
                let options = [{value: 0, text: 'выходного'}]
                options.push(...this.shift_options);
                return options;
            } else {
                return this.shift_options;
            }

        },
        others_shift_options() {
            return this.shift_options;
        }
    },
    methods: {
        onRemove() {
            if (confirm('Удалить правило?')) {
                this.$emit('remove-me');
            }
        }
    }
}
</script>
