// Заполнение первоначальными данными
export const INIT_EMP_COUNT = 4;
export const INIT_SHIFT_DEMAND = 0;


export function newData() {
    let el = document.getElementById('initial-schedule-fields');
    if (el) {
        let init = JSON.parse(el.textContent);
        if (!init.shifts) {
            // Compatibility with single-shift format
            init.shifts = [{
                title: '',
                demands: init.demands,
                week_demands: init.week_demands,
            }];
        }
        return init;
    } else {
        return null;
    }
}

export function calcEditorTitle() {
    // TODO: Более прямой метод определения страницы
    let call_method = window.MY_URLS && window.MY_URLS.scheduleCallMethod;  // CREATE, UPDATE, undefined
    if (call_method === 'CREATE') {
        return 'Создание плана';
    } else if (call_method === 'UPDATE') {
        return 'Редактирование плана';
    } else {
        return 'Планировщик';
    }
}
