<template>
<tr>
    <td
        v-if="readOnly"
        class="td_num_empl"
    >
        <strong>{{ idx+1 }}</strong>
    </td>
    <td
        v-else
        :class="['td_edit_empl', {'err_emp_prop': hasPropErr}]"
        v-b-tooltip.hover title="Редактировать сотрудника">
        <b-link
            class="nav-link"
            href="#edit-employee"
            @click="$emit('edit-employee', emp)"
            data-toggle="tooltip"
            data-placement="top"
        >
            <i class="fas fa-user-edit"></i>
        </b-link>
    </td>

    <td
        class="td_pin_empl"
    >
        <b-link
            v-if="empCount > 1"
            @click.prevent="switchPin"
            :class="['nav-link', {'text_pink': isPinned}]"
            :id="'pin-cell-' + emp.local_id"
            ref="pinLink"
        >
            <i class="fa fa-thumbtack"></i>
        </b-link>
        <b-link
            v-else
            :class="['nav-link', 'disabled']"
        >
            <i class="fa fa-thumbtack"></i>
        </b-link>
    </td>
    <td class="empl_name">
        <template v-if="readOnly">{{ emp.name }}</template>
        <input
          v-else
          :class="['form-control', 'form-control-sm', {'err_emp_name': hasPropErr}]"
          type="text"
          :value="emp.name"
          v-on:change="nameChanged($event, emp.local_id)"
          :placeholder="(idx +1) + ' ...'"
        />
    </td>
    <EmpTableCell
        v-for="(shift, day_idx) in emp.shifts.slice(0, daysCount)"
        :key="day_idx"
        :shift="shift"
        :day_idx="day_idx"

        :days-count="daysCount"
        :shift-count="shiftCount"
        :emp_local_id="emp.local_id"
        :holy-ends="holyEnds.includes(day_idx+1)"
        :holy-starts="holyStarts.includes(day_idx+1)"
        :read-only="readOnly"
        :status-slices="statusSlices"
        @show-forbid-prompt="showForbidPrompt"
    />
    <td
        v-if="validate || readOnly"
        class="td_total_shift"
    >
        {{ total }}
    </td>
    <td
        v-for="(t, idx) of perShiftTotals"
        :key="'per_shift_' + idx"
        class="td_total_shift"
    >
        {{ t }}
    </td>
    <td
        v-if="showHourTotal"
        class="td_total_shift"
    >
        {{ hourTotal }}
    </td>

    <b-popover
        v-if="showPinPrompt"
        :id="'pin-prompt-' + emp.local_id"
        triggers="focus"
        :target="'pin-cell-' + emp.local_id"
        placement="bottomright"
        boundary="window"
        noninteractive
        @hidden="$emit('set-pin-prompted')"
    >
        Чтобы отобразить всех сотрудников, нажмите на эту кнопку ещё раз.<br/>
        Стрелки над ней позволяют переключаться между сотрудниками
    </b-popover>

    <b-popover
        v-if="forbid.promptId !== null"
        show
        :id="'forbid-prompt-' + emp.local_id"
        triggers="focus"
        :target="forbid.promptId"
        boundary="window"
        noninteractive
        title="Не будет работать:"
        @hidden="forbid.promptId = null"
    >
        <nl2br tag="span" :text="shiftNameList" />
    </b-popover>
</tr>
</template>

<script>
import {getShiftIdx, hoursWorked, isWorkingDay} from '../../lib/shift_statuses';
import hoursMan from "../../model/HoursMan";
import {trackEvent} from "../../model/EventLog";
import EmpTableCell from "./EmpTableCell";
import plan_shifts from '../../model/PlanShifts';

export default {
    name: "PlanEditorTableRow",
    components: {
      EmpTableCell
    },
    props: ['emp', 'idx', 'isPinned', 'showPinPrompt', 'empCount',
        'daysCount', 'holyStarts', 'holyEnds',
        'validate', 'readOnly', 'hasPropErr', 'shifts', 'shiftCount',
        'hourSlices', 'shiftHours', 'showPerShiftTotal', 'showHourTotal'],
    data() {
      return {
        'forbid': {
          'promptId': null,
          'idxList': [],
        },
      }
    },
    methods: {
        nameChanged(event, local_id) {
          const v = event.target.value.trim();
          this.$parent.$emit('change-emp-name', {'local_id': local_id, 'value': v});
        },
        switchPin() {
          if (this.isPinned) {
            trackEvent('pin:off',
                {'cur_idx': this.idx, 'emp_cnt': this.empCount});
            this.$emit('unpin');
          } else {
            trackEvent('pin:on',
                {'idx': this.idx, 'emp_cnt': this.empCount});
            this.$emit('pin');
          }
        },
        showForbidPrompt(event) {
            this.forbid.promptId = null;
            let self = this;
            this.$nextTick(function () {
              self.forbid.idxList = event.idxList;
              self.forbid.promptId = event.promptId;
              self.$nextTick(function () {
                setTimeout(function () {
                  event.target.$el.focus();
                }, 50);
              });
            });
        },
    },
    computed: {
        total() {
            return this.emp.shifts.map(function (c) {
                return isWorkingDay(c.status) ? 1 : 0;
            }).reduce(function (x, y) {
                return x + y;  // Есть ли более человечный способ подсчёта суммы?
            });
        },
        hourTotal() {
            let hours = this.shiftHours;
            return this.emp.shifts.map(function (c) {
                return hoursWorked(c.status, hours);
            }).reduce(function (x, y) {
                return x + y;  // Есть ли более человечный способ подсчёта суммы?
            });
        },
        perShiftTotals() {
            if (this.showPerShiftTotal) {
                let totals = Array.from({length: this.shiftCount}, () => 0);
                for (let cell of this.emp.shifts) {
                    let sh_idx = getShiftIdx(cell.status);
                    if (sh_idx > 0) {
                        totals[sh_idx - 1] += 1;
                    }
                }
                return totals;
            } else {
                return [];
            }
        },
        statusSlices() {
            if (this.hourSlices) {
                return hoursMan.makeStatuses(this.hourSlices, this.emp.shifts);
            } else {
                return null;
            }
        },
        shiftNameList() {
          let shifts = this.shifts,
              idxList = this.forbid.idxList;
          if (idxList.length > 1) {
            return idxList.map((idx) => {
              return plan_shifts.shiftName(shifts[idx-1], idx);
            }).join('\n');
          } else {
            let idx = idxList[0];
            return plan_shifts.shiftName(shifts[idx-1], idx);
          }
        },
    },
    watch: {
      showPinPrompt: function(newVal) {
        if (newVal) {
          let self = this;
          this.$nextTick(() => {
            this.$nextTick(() => {
              // Это нужно, чтобы подсказка отключалась любым кликом, убирающим фокус
              setTimeout(() => {
                self.$refs.pinLink.blur();  // Без этого действия, фокус не срабатывает на первой и последней строчке
                self.$refs.pinLink.focus();
              }, 100);
              // self.$root.$emit('bv::show::popover', 'pin-prompt-' + self.emp.local_id);
            });
          });

        }
      }
    }
}
</script>

