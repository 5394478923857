import {arrayEquals} from './arrays'

if (!Object.entries) {
    Object.entries = function (obj) {
        let ownProps = Object.keys(obj);
        let i = ownProps.length;
        let resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
        return resArray;
    };
}

if (!Object.fromEntries) {
    Object.fromEntries = function (iterable) {
        return [...iterable].reduce((obj, [key, val]) => {
            obj[key] = val;
            return obj
        }, {})
    };
}

export function setIfChanged(obj, propName, newVal, defaultVal) {
    if (obj[propName] === undefined && newVal === defaultVal) {
        return false;
    }
    if (obj[propName] !== newVal) {
        if (arrayEquals(obj[propName], newVal)) {
            return false;
        } else {
            obj[propName] = newVal;
            return true;
        }
    } else {
        return false;
    }
}

export function withoutNulls(obj) {
    return Object.fromEntries(
        Object.entries(obj)
            .filter((entry) => entry[1] !== null))
}
