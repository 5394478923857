export function parseEmpCnt(state) {
    let empCnt = state.empCnt.replace(/[^0-9]+/g, '');
    empCnt = parseInt(empCnt);
    if (isNaN(empCnt)) {
        state.error_msg = 'Нужно задать количество сотрудников.';
    } else if (empCnt <= 0) {
        state.error_msg = 'Сотрудников должно быть больше нуля.';
    } else if (empCnt > 200) {
        state.error_msg = 'Задайте разумное количество сотрудников.\nТакой большой штат обычно разбивают на команды.';
    }
    return empCnt;
}
