<template>
<div>
  <table :class="['table', 'scheduler', hourSlices ? 'thead_hours' : 'thead_days']">
    <thead>
      <!-- Days header -->
      <tr>
        <th
            v-if="!readOnly"
            class="th_add_empl"
            style="cursor: pointer"
            rowspan="2"
            v-on:click="showAddEmp"
            v-b-tooltip.hover
            title="Добавить сотрудника">
            <i class="fas fa-user-plus"></i>
        </th>
        <th class="th_num_empl" rowspan="2" v-else>&nbsp;</th>

        <!-- Листалка запинненых сотрудников -->
        <th class="th_pin_empl" rowspan="2" v-if="pin.idx !== null && empCount > 1">
<!--            [ &#8593; {{ employees[pin.idx - 1].name || 'Предыдущий' }} ]-->
            <i v-if="pin.idx > 0"
               @click="pinPrev"
               style="cursor: pointer"
               class="fas fa-sort-up"></i>
            <span v-else>&nbsp;</span>
            <br/>
            <i v-if="pin.idx + 1 < empCount"
               @click="pinNext"
               style="cursor: pointer"
               class="fas fa-sort-down"></i>
          <span v-else>&nbsp;</span>
        </th>
        <th class="th_pin_empl" rowspan="2" v-else>&nbsp;</th>

        <th class="th_empl_name" rowspan="2">Сотрудник</th>

        <th
          v-for="d in daysCount"
          :key="d"
          :class="['weekday', {
            'holiday': !holyRemove.includes(d) && (weekends.includes(d) || holyAdd.includes(d)),
            'holy-start': holyStarts.includes(d),
            'holy-end': holyEnds.includes(d),
            }]"
        >
          {{ d }}<span v-if="holyAdd.includes(d) || holyRemove.includes(d)">*</span>
          <table v-if="hourSlices" class="slice_head">
            <tr>
              <td
                v-for="s of hourSlices[d-1]"
                v-bind:key="s"
              >
                {{ s >= 10 ? s : '0' + s }}
              </td>
            </tr>
          </table>
        </th>

        <th
          v-if="validate || readOnly"
          class="th_total_shift"
          rowspan="2"
        >
          <!-- b-link
              class="dashed_link_white"
              href=""
              v-b-tooltip.hover
              title="Выбрать суммы"
          -->
          <template v-if="showPerShiftTotal || showHourTotal">Смен</template>
          <template v-else>Всего</template>
          <!-- /b-link -->
        </th>

        <template v-if="showPerShiftTotal">
          <th
              v-for="idx in shiftCount"
              :key="'per_shift_' + idx"
              class="th_total_shift"
              rowspan="2"
          >
            {{ idx }}
          </th>
        </template>
        <th class="th_total_shift" rowspan="2" v-if="showHourTotal" >Часов</th>
      </tr>

      <tr>
        <th v-for="(wd, idx) in weekdayNames"
            :class="dayCssList(idx+1)"
            :key="idx"
        >
            {{ wd }}
        </th>
      </tr>
    </thead>

    <tbody>
      <!-- Employees -->
      <template v-for="(emp, idx) in employees">
        <PlanEditorTableRow
            v-if="pin.idx === null || pin.idx === idx"
          v-bind:emp="emp"
          v-bind:idx="idx"
          v-bind:emp-count="empCount"
          v-bind:days-count="daysCount"
          v-bind:holy-starts="holyStarts"
          v-bind:holy-ends="holyEnds"
          v-bind:validate="validate"
          v-bind:readOnly="readOnly"
          v-bind:has-prop-err="errEmpPatterns.includes(idx)"
          v-bind:shifts="shifts"
          v-bind:shiftCount="shiftCount"
          v-bind:hourSlices="hourSlices"
          v-bind:shiftHours="shiftHours"
          v-bind:showPerShiftTotal="showPerShiftTotal"
          v-bind:showHourTotal="showHourTotal"
          v-bind:is-pinned="pin.idx === idx"
          v-bind:showPinPrompt="pin.showPrompt && !pin.prompted"

          :key="emp.local_id"
          @edit-employee="$refs.empPropsForm.showForEmp(emp)"
          @pin="pinEmp(idx)"
          @unpin="pin.prompted=true; pin.idx=null"
          @set-pin-prompted="pin.prompted=true"
          @click="pin.prompted=true"
        />
      </template>
    </tbody>

    <!--    <tr><td :colspan="daysCount+2">&nbsp;</td></tr>-->
    <!-- Demands -->

    <template v-if="showDemands && !hourSlices">
      <PlanEditorDemandRow
        v-for="(sh, idx) in shifts"
        :key="idx"
        v-bind:demands="sh.demands"
        v-bind:shift_idx="idx+1"
        v-bind:title="sh.title"
        v-bind:showIdx="shiftCount > 1"
        v-bind:days-count="daysCount"
        v-bind:employees="employees"
        v-bind:holy-starts="holyStarts"
        v-bind:holy-ends="holyEnds"
        v-bind:validate="validate"
        v-bind:readOnly="readOnly"
        v-bind:err_codes="err_codes"
        v-bind:shiftHours="shiftHours"
        v-bind:totalsColSpan="totalsColSpan"
        v-bind:isMultiShift="shiftCount > 1"
      />
    </template>

    <PlanEditorHourTotalRow
      v-else-if="showDemands"
      v-bind:demands="shifts[0].demands"
      v-bind:days-count="daysCount"
      v-bind:employees="employees"
      v-bind:holy-starts="holyStarts"
      v-bind:holy-ends="holyEnds"
      v-bind:hourSlices="hourSlices"
    />
  </table>

    <EmpPropsForm
        v-if="!readOnly"
        ref="empPropsForm"
        :daysCount="daysCount"
        :shift-count="shiftCount"
        :shifts="shifts"
    />

    <b-modal
        v-if="!readOnly"
        title="Добавление сотрудников"
        id="add-employee"
    >
      <b-form-group
          label="Сколько добавить:"
          label-class="label_violet"
          label-for="add-emp-num"
          label-cols-sm="4"
      >
        <b-form-input id="add-emp-num" type="number" min="1" max="99" v-model="empToAdd" />
      </b-form-group>
        <template v-slot:modal-footer="{ close }">
            <b-button
              class="btn_default"
              @click="close(); onOkAddEmp()"
            >
              Ок
            </b-button>
            <b-button
              variant="light"
              @click="close()"
            >
              Отмена
            </b-button>
        </template>
    </b-modal>
</div>
</template>


<script>
import PlanEditorTableRow from "./EmpTableRow";
import PlanEditorDemandRow from './DemandRow';
import PlanEditorHourTotalRow from './HourTotalRow';
import EmpPropsForm from "./EmpPropsForm";
import {modalTracker, trackEvent} from "../../model/EventLog";

export default {
  name: "PlanEditorTable",
  components: {
    PlanEditorTableRow,
    PlanEditorDemandRow,
    PlanEditorHourTotalRow,
    EmpPropsForm,
  },
  props: ['monthInfo', 'shifts', 'employees',
    'validate', 'readOnly', 'err_codes',
    'shiftCount', 'hourSlices', 'shiftHours', 'withHoursMan'],
  data() {
    return {
      daysCount: this.monthInfo.daysCount,
      firstWeekDay: this.monthInfo.firstWeekDay,
      weekends: this.monthInfo.weekends,
      weekdayNames: this.monthInfo.weekdayNames,
      holyAdd: this.monthInfo.holyAdd,
      holyRemove: this.monthInfo.holyRemove,
      // unused: month, currentMonth
      pin: {
        idx: null,
        prompted: false,
        showPrompt: false
      },
      empToAdd: 1,
    };
  },
  methods: {
    pinEmp(idx) {
      this.pin.idx=idx;
      this.$el.scrollIntoView();
      let self = this;
      this.$nextTick(function () {
        setTimeout(function () {
          self.pin.showPrompt = true;
        }, 100);
      });
    },
    pinNext() {
      this.pin.prompted = true;
      trackEvent('pin:next',
          {'cur_idx': this.pin.idx, 'emp_cnt': this.empCount});
      this.pin.idx++;
    },
    pinPrev() {
      this.pin.prompted = true;
      trackEvent('pin:prev',
          {'cur_idx': this.pin.idx, 'emp_cnt': this.empCount});
      this.pin.idx--;
    },
    showAddEmp() {
      this.empToAdd = 1;
      window.location.hash = 'add-employee';
      this.$bvModal.show('add-employee');
      modalTracker.setField('confirmed', false);
      modalTracker.setField('old_count', this.employees.length);
    },
    onOkAddEmp() {
      let count = Number(this.empToAdd);
      if (!isNaN(count) && count >= 1) {
        modalTracker.setField('confirmed', true);
        modalTracker.setField('added_count', count);
        this.$emit('add-employee', count);
      } else {
        modalTracker.setField('added_count', this.empToAdd);
        modalTracker.setField('err', 'NaN');
      }
    },
    dayCssList(d) {
        return ['weekday_name', {
            'holiday': !this.holyRemove.includes(d) && (this.weekends.includes(d) || this.holyAdd.includes(d)),
            'holy-start': this.holyStarts.includes(d),
            'holy-end': this.holyEnds.includes(d),
        }];
    }
  },
  computed: {
    holyStarts() {
      let starts = [];
      let prev = 0;
      for (let h of this.weekends) {
        if (h > prev+1) {
          starts.push(h);
        }
        prev = h;
      }
      return starts;
    },
    holyEnds() {
      let ends = [];
      let h = 0;
      for (let next of this.weekends) {
        if (h < next-1) {
          ends.push(h);
        }
        h = next;
      }
      if (h !== this.daysCount) {
        ends.push(h);
      }
      return ends;
    },
    errEmpPatterns() {
      for (let code of this.err_codes) {
        if (typeof(code) === 'object' && code.emp_patterns) {
          return code.emp_patterns;
        }
      }
      return [];
    },
    showHourTotal() {
      return Boolean(this.withHoursMan || this.hourSlices || this.shiftHours);
    },
    showPerShiftTotal() {
      return (this.validate || this.readOnly) && this.shiftCount > 1;
    },
    showDemands() {
      // В режиме display суммы по дням не показываем
      return this.validate || !this.readOnly;
    },
    totalsColSpan() {
      let colspan = 1;
      if (this.showHourTotal) {
        colspan++;
      }
      if (this.showPerShiftTotal) {
        colspan += this.shiftCount;
      }
      return colspan;
    },
    empCount() {
      return this.employees.length;
    },
  }
}
</script>


<style scoped>
</style>
