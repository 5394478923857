<template>
    <div class="padd_bott_15">
        <hr v-if="!isActual" />
        <div v-if="report.error">
            <span class="error_text">
                <i class="fas fa-exclamation-circle"></i> {{ report.error }}
            </span>
        </div>
        <b-row>
            <b-col md="4" sm="4">
            <!-- Время создания -->
                <b-nav>
                    <b-nav-text v-if="isActual">
                        <i class="fas fa-check-circle text_green"></i> Актуальная версия
                    </b-nav-text>
                    <b-nav-text v-else class="text-muted">Версия от {{ humanDate }}</b-nav-text>
                </b-nav>
            </b-col>
            <b-col md="8" sm="8">
                <!-- Название. Режим редактирования -->
                <template v-if="label.edit_mode">
                    <b-input-group prepend="Название версии:" class="mb-3" size="sm">
                        <b-form-input
                            ref="labelInput"
                            size="sm"
                            class=""
                            @keyup.enter.native="storeNewLabel"
                            @keyup.esc.native="closeLabelEditor"
                            @blur.native="closeUnchangedLabelEditor"
                            @focusout.native="closeUnchangedLabelEditor"
                            v-model="label.new_value"
                        />
                        <b-input-group-append>
                            <b-button
                                @click="storeNewLabel"
                                size="sm"
                                class="btn_yellow"
                            >
                                <i class="fa fa-check"></i>
                            </b-button>
                            <b-button
                                @click="closeLabelEditor"
                                size="sm"
                                class="btn_yellow"
                            >
                                <i class="fa fa-times" ></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </template>
                <!-- Название. Режим отображения -->
                <template v-else>
                    <b-nav align="right">
                        <b-nav-text v-if="revision.label" class="text-muted">
                            <i class="fas fa-angle-double-left"></i>
                            {{ revision.label }}
                            <i class="fas fa-angle-double-right"></i>
                        </b-nav-text>
                        <b-nav-item>
                            <b-button-group size="sm">
                                <b-button
                                    @click="openLabelEditor"
                                    size="sm"
                                    class="btn_yellow"
                                >
                                    <i class="far fa-edit"></i>
                                    Название версии
                                </b-button>
                                <!-- Актуализация -->
                                <b-button
                                    v-if="!isActual"
                                    @click="setAsActual"
                                    size="sm"
                                    class="btn_yellow"
                                >
                                    <i class="fa fa-upload"></i>
                                    Сделать актуальной
                                </b-button>
                            </b-button-group>
                        </b-nav-item>
                        </b-nav>
                </template>
            </b-col>
        </b-row>
        <div class="table-responsive">
            <PlanEditorTable
                v-bind:monthInfo="monthInfo"
                v-bind:shifts="revision.shifts"
                v-bind:employees="revision.employees"
                v-bind:validate="true"
                v-bind:readOnly="true"
                v-bind:err_codes="[]"
                v-bind:shiftCount="revision.shifts.length"
                v-bind:hourSlices="hourSlices"
                v-bind:shiftHours="shiftHours"
            />
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

import PlanEditorTable from './EmpTable'
import hoursMan from "../../model/HoursMan";
import model from "../../model/Revisions";

export default {
    name: 'Revision',
    components: {PlanEditorTable},
    props: ['revision', 'monthInfo', 'guid', 'redirect_url'],
    data() {
        return {
            report: {
                loading: false,
                error: '',
            },
            label: {
                edit_mode: false,
                new_value: '',
            }
        };
    },
    computed: {
        isActual() {
            return !this.revision.ts;
        },
        humanDate() {
            return moment.unix(this.revision.ts).format('DD MMMM YYYY LT');  //
        },
        hourSlices() {
            // FIXME: почти дублируется с PlanEditor
            let rev = this.revision;
            if (rev.withHoursMan) {
                return hoursMan.makeSlices(rev.employees, this.monthInfo.daysCount);
            } else {
                return null;
            }
        },
        shiftHours() {
            // FIXME: почти дублируется с PlanEditor
            if (typeof(this.revision.shifts[0].duration) === 'number') {
                return this.revision.shifts.map((sh) => sh.duration);
            } else {
                return null;
            }
        },
    },
    methods: {
        setAsActual() {
            let redirectUrl = this.redirect_url;
            model.sendRequest(this.report,
                'set_actual_rev', {rev_ts: this.revision.ts},
            function () {
                window.location = redirectUrl;
            });
        },
        openLabelEditor() {
            this.label.new_value = this.revision.label || '';
            this.label.edit_mode = true;
            Vue.nextTick(() => this.$refs.labelInput.focus());
        },
        closeLabelEditor() {
            this.label.edit_mode = false;
        },
        closeUnchangedLabelEditor() {
            if (this.label.new_value.trim() === (this.revision.label || '')) {
                this.closeLabelEditor();
            }
        },
        storeNewLabel() {
            let newLabel = this.label.new_value.trim();
            if (newLabel === (this.revision.label || '')) {
                this.closeLabelEditor();
                return;
            }
            model.sendRequest(this.report,
                'set_rev_label',
                {rev_ts: this.revision.ts,
                    label: newLabel},
                this.onLabelStored.bind(this));
        },
        onLabelStored() {
            this.revision.label = this.label.new_value;
            this.closeLabelEditor();
        }
    }
}
</script>
