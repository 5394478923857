<template>
<td
    :class="[
      'cell_w_h',
       (!statusSlices || statusSlices[day_idx] === null) ? dayProps.cell_class : 'cell_free',
       {'selected': shift.selected,
        'holy-start': holyStarts,
        'holy-end': holyEnds,
        'cell_part_rest': hasForbidden,
        }]"
    v-on:click="!readOnly && $parent.$parent.$emit('switch-shift-selection', [emp_local_id, day_idx])"
    v-on:contextmenu.prevent="showForbiddenShifts"
>
  <table
      v-if="statusSlices && statusSlices[day_idx] !== null"
      class="hour_statuses"
  >
    <tr>
      <td
          v-for="(s, s_idx) of statusSlices[day_idx]"
          v-bind:key="s_idx"
          :class="'cell_' + s.status"
      ></td>
    </tr>
  </table>
  <template v-else>
    {{ shiftLetter }}
  </template>
  <b-link
      ref="promptTarget"
      :id="promptTrgId"
      @focusout="promptTrgContent = ''"
  >{{ promptTrgContent }}</b-link>
</td>
</template>

<script>
import {dayLetter, dayStatus, shiftStatuses} from "../../lib/shift_statuses";

export default {
  name: 'EmpTableCell',
  props: [
      'shift', 'day_idx', 'emp_local_id',
      'daysCount', 'shiftCount', 'statusSlices',
      'holyStarts', 'holyEnds', 'readOnly',
  ],
  data() {
    return {
      promptTrgContent: '',  // Хак для нормальной работы всплывашки
    };
  },
  methods: {
    showForbiddenShifts(event) {
      event.preventDefault();
      let status = this.shift.status;
      if (typeof(status) === 'object' && status.forbidden.length > 0) {
        this.promptTrgContent = '-';
        this.$emit('show-forbid-prompt',
            {'promptId': this.promptTrgId, 'idxList': status.forbidden, 'target': this.$refs.promptTarget});
      }
    },
  },
  computed: {
    promptTrgId() {
      return 'status-prompt-trg-' + this.emp_local_id + '-' + this.day_idx;
    },
    dayProps() {
      return shiftStatuses[dayStatus(this.shift.status)];
    },
    shiftLetter() {
      return dayLetter(this.shift.status, this.shiftCount);
    },
    hasForbidden() {
      if (typeof(this.shift.status) !== 'object') {
        return false;
      }
      if (this.shift.status.forbidden.length === 0) {
        return false;
      }
      return (this.shift.status.forbidden.length > 1) || !this.shift.status.forbidden.includes(0);
    },
  }
}
</script>
