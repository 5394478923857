export function setLocationHash(hash) {
    if (history && history.pushState) {
        history.pushState(null, null, hash);
    }
    else {
        window.location.hash = hash;
    }
}

window.setLocationHash = setLocationHash;
