<template>
  <div>
    <div
        v-if="report.run_message"
        :class="['padd_bott_15', 'pl-status-message-' + report.status]"
    >
      <i class="fas fa-exclamation-circle"></i>&nbsp;
      <nl2br :tag="report.just_finished ? 'b' : 'span'" :text="report.run_message" />
      <template v-if="report.fix_info">
        <br/><span v-html="report.fix_info" />
      </template>
    </div>
    <div class="text-center">
      <a href="#recalc_range"
         v-b-modal.modal_recalc_range
         class="dashed_link"
      >
        <i class="fas fa-calendar-alt"></i>&nbsp;

        <span v-if="horizon.from_day === 1 || !horizon.from_day">
          <span v-if="horizon.to_day === daysCount || !horizon.to_day">весь месяц</span>
          <span v-else>c 1 до {{ horizon.to_day }} числа</span>
        </span>
        <span v-else>
          <span v-if="horizon.to_day === daysCount || !horizon.to_day">c {{ horizon.from_day}} числа до конца месяца</span>
          <span v-else>c {{ horizon.from_day}} до {{ horizon.to_day }} числа</span>
        </span>

        <span v-if="prevPlan.guid && prevPlan.consider">
          <br/>С учётом предыдущего плана
        </span>
        <span v-else-if="prevPlan.guid && !prevPlan.consider">
          <br/>Без учёта предыдущего плана
        </span>
      </a>
      <b-modal
        id="modal_recalc_range"
        size="sm"
        scrollable
        centered
        title="Диапазон дат"
        @shown="loadHorizon"
      >
      <div class="container">
        <p class="help_text">
          <i class="fas fa-exclamation-circle"></i>
          Если нужно составить план на отрезок месяца - укажите его.
        </p>
        <form>
          <div class="form-group row">
            <label class="col-md-2 col-2">с</label>
            <div class="col-md-3 col-3">
                <b-form-input
                      size="sm"
                      v-model="selected_horizon.from_day"
                >
                </b-form-input>
            </div>
            <div class="col-md-7 col-7">
              числа
            </div>
          </div>
          <div v-if="selected_horizon.errors.from" class="form-group row error_text">
            <div class="col-md-12 col-12">{{ selected_horizon.errors.from }}</div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-2">
              до
            </label>
            <div class="col-md-3 col-3">
                <b-form-input
                    size="sm"
                    :class="{'invalid': selected_horizon.errors.to}"
                    v-model="selected_horizon.to_day"
                >
                </b-form-input>
            </div>
            <div class="col-md-7 col-7">
              числа
            </div>
          </div>
          <div v-if="selected_horizon.errors.to" class="form-group row error_text">
            <div class="col-md-12 col-12">{{ selected_horizon.errors.to }}</div>
          </div>

          <template v-if="consider_prev !== null">
            <hr />
            <b-form-checkbox v-model="consider_prev">
              учитывать <b-link target="_blank" :href="prevPlan.url">предыдущий план</b-link>
            </b-form-checkbox>
            <p class="help_text">
              <i class="fas fa-question-circle"></i>
              <b-link target="_blank" href="/tutorial/previous_schedule_continue/#content-start"> что это значит...</b-link>
            </p>
          </template>

          <div v-if="selected_horizon.errors.all" class="form-group row error_text">
            <div class="col-md-12 col-12">{{ selected_horizon.errors.all }}</div>
          </div>
          <hr/>
          <div class="form-group row">
            <div class="col-md-12 col-12">
                <b-form-checkbox
                    v-model="selected_horizon.instant_recalc"
                >
                  сразу пересчитать план
                </b-form-checkbox>
            </div>
          </div>
        </form>
      </div>
      <template v-slot:modal-footer="{ close }">
        <b-button
          class="btn_default"
          @click="setHorizon() && close()"
        >
          Ок
        </b-button>
        <b-button
          variant="light"
          @click="close()"
        >
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
    <div class="padd_top_15 text-center">
      <b-button
        class="btn_default"
        v-on:click="$emit('fill-scheduler')"
        :disabled="report.loading"
      >
        <i class="fas fa-magic"></i> Заполнить
      </b-button>
    </div>
    </div>
</template>

<script>
import {parseIntDefault} from '../../lib/num';

export default {
  name: "PlanEditorRunButton",
  props: ['report', 'isUpdateMode', 'isAnonymous', 'hasChanges', 'horizon', 'daysCount', 'prevPlan'],
  data: function () {
    return {
      selected_horizon: {
        from_day: 1, to_day: null,
        errors: this.initialHorizonErrors(),
        instant_recalc: true,
      },
      consider_prev: null,
    }
  },
  methods: {
    setHorizon () {
      // Задаёт горизонт планирования исходя из заполненных полей
      // Возвращает bool, успешно ли применено изменение
      let hrz = this.selected_horizon;
      let from_day = parseIntDefault(hrz.from_day, 1);
      if (isNaN(from_day)) {
        hrz.errors.from = 'Должно быть числом';
        return false;
      } else if (from_day < 1 || from_day > this.daysCount) {
        hrz.errors.from = 'Выходит за пределы месяца';
        return false;
      }
      hrz.errors.from = '';

      let to_day = parseIntDefault(hrz.to_day, this.daysCount);
      if (isNaN(to_day)) {
        hrz.errors.to = 'Должно быть числом';
        return false;
      } else if (to_day < 1 || to_day > this.daysCount) {
        hrz.errors.to = 'Выходит за пределы месяца';
        return false;
      }
      hrz.errors.to = '';
      if (from_day > to_day) {
        hrz.errors.all = 'Первое число не должно быть больше второго';
        return false;
      } else {
        this.$emit('set-horizon',
                {from_day: from_day, to_day: to_day,
                  consider_prev: this.consider_prev,
                  instant_recalc: hrz.instant_recalc});
        return true;
      }
    },
    loadHorizon() {
      let hrz = this.selected_horizon;
      hrz.from_day = this.horizon.from_day || 1;
      hrz.to_day = this.horizon.to_day || null;
      hrz.errors.from = '';
      hrz.errors.to = '';
      hrz.errors.all = '';

      if (this.prevPlan.guid) {
        this.consider_prev = this.prevPlan.consider;
      }
    },
    initialHorizonErrors() {
      return {
        from: '',
        to: '',
        all: '',
      }
    }
  }
}
</script>

<style scoped>
</style>
