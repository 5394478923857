<template>
<div class="padd_top_20">
    <div
        v-if="report.save_message"
        :class="['padd_bott_15', 'pl-status-message-' + report.status]"
    >
        <i class="fas fa-exclamation-circle"></i>&nbsp;
        <nl2br :tag="report.just_finished ? 'b' : 'span'" :text="report.save_message" />
        <template v-if="report.fix_info">
          <br/><span v-html="report.fix_info" />
        </template>
    </div>

    <b-button
        class="btn_default"
        v-on:click="isAnonymous ? $emit('force-to-signup') : $emit('save-scheduler')"
        :disabled="!hasChanges"
    >
        <i class="far fa-save"></i> Сохранить
    </b-button>
    <div class="paddings_15" v-if="showRevLink">
        <a :href="revisions.url">
            <i class="fas fa-history"></i> Предыдущие сохранённые версии
        </a>
    </div>
    <div class="help_text" v-if="isAnonymous && hasChanges"> Потребуется регистрация</div>

    <div class="padd_top_15" v-if="report.last_uri">
        <h4 v-if="isUpdateMode">Постоянная ссылка на план:</h4>
        <h4 v-else-if="hasChanges">Предыдущая версия плана:</h4>
        <h4 v-else>Ссылка на эту версию плана:</h4>
        <p class="help_text text-center">
            <i class="fas fa-exclamation-circle"></i>
            Доступна всем.
            <span v-if="isUpdateMode">Но редактировать можете только вы.</span>
            <span v-else>Без возможности редактирования.</span>
        </p>
        <span v-if="hasChanges" class="text-secondary">
            {{ report.last_uri }}
        </span>
        <a
            target="_blank"
            :href="report.last_uri"
            v-else
        >
            {{ report.last_uri }}
        </a>
    </div>
</div>
</template>

<script>
export default {
    name: "PlanEditorSaveButton",
    props: ['report', 'isUpdateMode', 'isAnonymous', 'hasChanges', 'revisions'],
    computed: {
        showRevLink() {
            return this.revisions && this.revisions.count > 0;
        },
    }
}
</script>

