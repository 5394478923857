if (!Date.now) {
    Date.now = function now() {
        return new Date().getTime();
    };
}

export function unixTime() {
    return Date.now() / 1000;
}

export const WEEKDAYS = [
    {'short': 'пн', 'full': 'понедельник'},
    {'short': 'вт', 'full': 'вторник'},
    {'short': 'ср', 'full': 'среда'},
    {'short': 'чт', 'full': 'четверг'},
    {'short': 'пт', 'full': 'пятница'},
    {'short': 'сб', 'full': 'суббота'},
    {'short': 'вс', 'full': 'воскресенье'},
]
