import Cookie from "js-cookie";
import axios from "axios";

export function markNotificationRead(id) {
    let data = new FormData();
    data.append('id', id);

    let request = {
        url: '/contacts/notification/mark_read/', // FIXME: Получать такие урлы из Django
        // contentType: 'application/json; charset=utf-8',
        method: 'POST',
        headers: {
            "X-CSRFToken": Cookie.get('csrftoken'),
            "X-Requested-With": "XMLHttpRequest",
        },
        data: data,
        dataType: "form",
    };
    axios(request);
    // TODO: В случае проблем со связью,
    //   сохранять в localStore, что id удалён и при следующем открытии сразу удалять плашку, а в фоне снова пытаться обратиться к api.
}
