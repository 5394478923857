import Vue from 'vue';
import {removeItemOnce} from "./arrays";

export let shiftStatuses = {
    // class - css-класс, которым отрисовываются ячейки и кнопки
    // title - надпись для кнопки
    // letter - буква для отображения в ячейке
    // code - обозначение для отправки шедулеру и для получения из него
    'rest': {cell_class: 'cell_rest', letter: 'Н', code: 'rest',
             btn_class: 'btn_rest', title: 'Работать не хочет',
             unfold_multi: 'part_rest'},
    'forced': {cell_class: 'cell_forced', letter: 'Р', code: 'work',
               btn_class: 'btn_forced', title: 'Должен работать',
               unfold_multi: 'dropdown', unfold_hours: true},
    'vacation': {cell_class: 'cell_vacation', letter: 'О', code: 'vacation',
                 btn_class: 'btn_vacation', title: 'Отпуск или больничный'},
    'free': {cell_class: 'cell_free', letter: 'Н', code: 0,
             btn_class: 'btn_free', title: 'Свободен'},
    'working': {cell_class: 'cell_working', letter: 'Р', code: 1,
                btn_class: 'btn_working', title: 'Запланирован автоматически'},
};

let codes = {};
for (let [status, info] of Object.entries(shiftStatuses)) {
    codes[info.code] = status;
    info.key = status;
}


export function dayStatus(status) {
    if (typeof(status) === "string") {
        return status;
    } else if (typeof(status) === 'object') {
        if (status.is_forced) {
            return status.shift_idx === 0 ? 'rest' : 'forced';
        } else if (status.forbidden.includes(0)) {
            return 'forced';
        } else {
            return status.shift_idx === 0 ? 'free' : 'working';
        }
    } else {
        throw "Unknown status type: " + typeof(status);
    }
}

export function hourShiftName(status) {
    return (status.start < 10 ? '0' + status.start : status.start)
        + '-' +
        (status.end < 10 ? '0' + status.end : status.end);
}


export function dayLetter(status, shiftCount) {
    if (typeof(status) === 'object' && status.start !== undefined) {
        return hourShiftName(status);
    }
    let day = dayStatus(status);
    if (shiftCount > 1 && (day === 'working' || day === 'forced')) {
        if (typeof(status) === "string") {
            return '1';
        } else {
            return status.shift_idx.toString();
        }
    } else {
        return shiftStatuses[day].letter;
    }
}

export function codeToStatus(code) {
    // `code` - Значение, пришедшее из бэкенда
    // Возвращает то, что нужно хранить в emptable модели
    // TODO: Привести api и ui в однозначное соответствие, чтобы эта функция вообще была не нужна
    if (typeof(code) === 'number' && code > 1) {
        return {
            is_forced: false,
            shift_idx: code,
            forbidden: [],
        }
    } else if (typeof(code) === 'object') {
        return code;
    } else {
        return codes[code];
    }
}

export function isWorkingAt(status, shift_idx) {
    return getShiftIdx(status) === shift_idx;
}

export function getShiftIdx(status) {
    if (status === 'working' || status === 'forced') {
        return 1;  // Односменный формат
    } else if (typeof(status) === 'object') {
        // Бывает равен нулю и при этом работает обязательно.
        // Что делать с такой ситуацией, непонятно.
        return status.shift_idx;
    } else if (typeof(status) === 'number') {
        return status;
    } else {
        return 0;
    }
}

export function isWorkingDay(status) {
    let ds = dayStatus(status);
    return (ds === 'working' || ds === 'forced');
}

export function hoursWorked(status, shiftHours) {
    if (isWorkingDay(status)) {
        if (status.start === undefined) {
            if (shiftHours) {
                let sh_idx = getShiftIdx(status);
                if (sh_idx > 0) {
                    return shiftHours[sh_idx - 1];
                }
            }
            return 0; // TODO: Не очень понятно, что делать с рабочими днями без заданного количества часов
        } else {
            let span = status.end - status.start;
            if (span <= 0) {
                span += 24;
            }
            return span;
        }
    } else {
        return 0;
    }
}

// *** Обновление ячейки ***

export function updateCell(cell, event) {
    // Изменяет cell.status в соответствии с изменениями статуса представленными в event
    // Возвращает флажок о том, есть ли изменения в исходном статусе
    if (typeof(event) === 'string') {  // Односменный формат,
        // либо статус при котором остальные подробности из ячейки можно удалить
        if (cell.status !== event) {
            if (typeof(cell.status) === 'string' || event === 'vacation') {
                cell.status = event;
                return true;
            } else {
                return updateByDay(cell.status, event);
            }
        } else {
            return false;
        }
    } else if (typeof(event) === 'object') {
        convertToComplex(cell);
        if (event.sh_idx !== undefined || event.shifts !== undefined) {
            return updateByShift(cell.status, event);
        } else {
            return updateByHours(cell.status, event);
        }
    } else {
        console.error('unknown event type', typeof(event), event);
    }
}

function updateByDay(status, event) {
    let changed = false;
    if (event === 'free') {
        if (status.is_forced || status.shift_idx !== 0) {
            status.is_forced = false;
            status.shift_idx = 0;
            changed = true;
        }
        if (status.forbidden.includes(0)) {
            removeItemOnce(status.forbidden, 0);
            changed = true;
        }
    } else if (event === 'forced') {
        if (!status.forbidden.includes(0)) {
            status.forbidden.push(0);
            changed = true;
        }
        if (status.is_forced && status.shift_idx === 0) {
            status.is_forced = false;
            //status.shift_idx = null;  // FIXME: поддержку null в api, да и по коду ui
            changed = true;
        }
        // FIXME: Как обеспечить, чтобы этот кейс не встретился, если смена осталась одна?
    } else if (event === 'rest') {
        if (!status.is_forced || status.shift_idx !== 0) {
            status.is_forced = true;
            status.shift_idx = 0;
            changed = true;
        }
        if (status.forbidden.length > 0) {
            status.forbidden.splice(0);
            changed = true;
        }
    } else {
        console.error('unknown event', typeof(event), event);
    }
    Vue.delete(status, 'start');
    Vue.delete(status, 'end');
    return changed;
}

function updateByShift(status, event) {
    let changed = false
    if (event.action === 'forced') {
        if (!status.is_forced || status.shift_idx !== event.sh_idx) {
            status.is_forced = true;
            status.shift_idx = event.sh_idx;
            changed = true;
        }
        if (status.forbidden.includes(event.sh_idx)) {
            removeItemOnce(status.forbidden, event.sh_idx);
            changed = true;
        }
    } else if (event.action === 'rest') {
        // В этой функции rest задаётся не на весь день, а на определённые смены
        // В случае на весь день - вызывается `updateByDay`
        if (status.forbidden !== event.shifts) {
            status.forbidden.splice(0, status.forbidden.length, ...event.shifts);
            changed = true;
        }
        if (event.shifts.includes(status.shift_idx) ||
            (status.is_forced === true && status.shift_idx === 0)) {
            status.is_forced = false;
            status.shift_idx = 0;  // FIXME: null?
            changed = true;
        }
    } else {
        console.error('unknown event', event);
    }
    Vue.delete(status, 'start');
    Vue.delete(status, 'end');
    return changed;
}

function updateByHours(status, event) {
    let changed = false;
    if (event.action === 'forced') {
        if (!status.is_forced || !status.shift_idx) {
            status.is_forced = true;
            status.shift_idx = 1;
            changed = true;
        }
        if (status.start !== event.start) {
            Vue.set(status, 'start', event.start);
            changed = true;
        }
        if (status.end !== event.end) {
            Vue.set(status, 'end', event.end);
            changed = true;
        }
    } else {
        console.error('unknown event', event);
    }
    return changed;
}

function convertToComplex(cell) {
    if (typeof(cell.status) === 'object') {
        return;
    } else if (cell.status === 'rest') {
        cell.status = { is_forced: true, shift_idx: 0, forbidden: [] };
    } else if (cell.status === 'forced') {
        cell.status = { is_forced: true, shift_idx: 1, forbidden: [] };
    } else if (cell.status === 'vacation') {
        // FIXME: shift_idx: vacation пока что нигде не поддерживается
        // NB! Подразумевается, что настоящие значения будут выставлены обновлялкой статуса
        //   Если же это конвертация ради конвертации, то надо оставлять просто vacation
        cell.status = { is_forced: true, shift_idx: 0, forbidden: [] };
    } else if (cell.status === 'free') {
        cell.status = { is_forced: false, shift_idx: 0, forbidden: [] };
    } else if (cell.status === 'working') {
        cell.status = { is_forced: false, shift_idx: 1, forbidden: [] };
    } else {
        console.error('unknown cell status', cell.status);
    }
}
