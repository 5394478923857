<template>
<b-form-group>
    <b-form-radio
            :id="'month_' + mnt.value"
            :value="mnt.value"
            v-model="monthState.selected"
            name="some-radios"
            v-for="mnt of monthChoices"
            :key="mnt.value"
    >
        {{ mnt.label }}
        <span v-if="mnt.comment" class="help_text"> {{ mnt.comment }}</span>
    </b-form-radio>
</b-form-group>
</template>

<script>
import months from "../../model/PlanMonth";

export default {
    name: 'MonthList',
    props: ['monthState', 'tmpl'],
    data() {
        return {
            monthChoices: this.genMonthChoices(this.monthState),
        }
    },
    methods: {
        genMonthChoices(state) {
            let choices = [];
            for (let [key, val] of months.genMonths(state.selected)) {
                let comments = [];

                if (key === state.prevMonth) {
                    if (state.currentDay <= 7) {
                        comments.push('только закончился');
                    } else {
                        comments.push('прошлый месяц');
                    }
                } else if (key === state.currentMonth) {
                    if (state.currentDay <= 7) {
                        comments.push('только начался');
                    } else if (state.currentDay > 22) {
                        comments.push('скоро закончится');
                    } else {
                        comments.push('сейчас идёт');
                    }
                } else if (key === state.nextMonth) {
                    if (state.currentDay > 22) {
                        comments.push('скоро начнётся');
                    } else {
                        comments.push('следующий месяц');
                    }
                }

                if (this.tmpl && key === this.tmpl.period) {
                    comments.push('из него копируем');
                }

                choices.push({'value': key, 'label': val, 'comment': comments.join(', ')})
            }
            return choices;
        },
    }
}
</script>
