<template>
  <div class="auto_scroll">
    <div
        v-if="prepopulated"
        class="error"
    >
      <i class="fas fa-exclamation"></i>&nbsp;
      План не сохранён<br/>
      <nl2br tag="span" :text="unsavingCause.message"></nl2br>
      <template v-if="unsavingCause.error === 402">
        <br/>
        <span v-html="runner.paymentInfo" />
      </template>
    </div>

    <h2>{{ title }}
      <span v-if="hasChanges" class="badge badge-light badge_status_plan">Есть несохранённые<br/> изменения</span>
    </h2>
<!--    <h2>{{ title }} <span class="badge badge-success badge_status_plan"><i class="fas fa-check"></i> сохранен</span></h2>-->
<!--    <h2>{{ title }} <span class="badge badge-secondary badge_status_plan">-->
<!--      <i class="fas fa-exclamation"></i> сохранен невалидным</span></h2>-->
    <PlanEditorHeader
        @set-status-for-all="emptable.setStatusForAll($data, $event) ? setUnsavedChanges() : null"
        @set-plan-title="setPlanTitle"
        v-bind:selectedMonth="monthInfo.month"
        v-bind:currentMonth="monthInfo.currentMonth"
        v-bind:planTitle="planTitle"
    />
    <div class="table-responsive">
      <PlanEditorTable
          v-bind:monthInfo="monthInfo"
          v-bind:shifts="shifts"
          v-bind:employees="employees"
          v-bind:validate="validate"
          v-bind:readOnly="false"
          v-bind:err_codes="report.err_codes"
          v-bind:shiftCount="shiftCount"
          v-bind:hourSlices="hourSlices"
          v-bind:shiftHours="shiftHours"
          v-bind:withHoursMan="withHoursMan"
          @add-employee="addEmployee"
          @change-demand="setUnsavedChanges(); demand.singleChange($data, $event)"
          @change-emp-name="setUnsavedChanges(); emptable.findEmp($data, $event.local_id).name = $event.value"
          @remove-employee="emptable.removeEmployee($data, $event) ? setUnsavedChanges() : null"
          @set-unsaved="setUnsavedChanges()"
          @switch-shift-selection="emptable.switchShiftSelection($data, $event)"
      />
    </div>

    <div class="container">
      <div class="mt-3 scheduler_dashboard">
        <b-card-group deck>
            <b-card no-body>
              <b-tabs card align="center">
                <b-tab title="Статусы" active>
                  <b-card-text class="text-center">
                    <StatusButtons
                      v-bind:shifts="shifts"
                      v-bind:isMulti="shiftCount > 1"
                      v-bind:withHoursMan="withHoursMan"
                      v-bind:usedHourIntervals="usedHourIntervals"
                      v-bind:selectedCount="selected_count"
                      @set-status-for-selected="emptable.setStatusForSelected($data, $event) ? setUnsavedChanges() : null"
                      @add-hour-interval-history="hoursMan.addToIntervalHistory($data, $event)"
                    />
                  </b-card-text>
                </b-tab>
                <b-tab
                    v-if="!withHoursMan"
                    title="Настройки"
                >
                  <b-card-text>
                      <b-nav vertical>
                          <WeeklyDemand
                            :shifts="shifts"
                            @batch-change-demand="setUnsavedChanges(); demand.batchChange($data, $event)"
                          />
                          <WorkPatternGlobal
                            :empPattern="empPattern"
                            :daysCount="monthInfo.daysCount"
                            @set-unsaved="setUnsavedChanges()"
                          />
                          <ShiftList
                              v-bind:shifts="shifts"
                              @applyShifts="setUnsavedChanges(); shift.applyShifts($data, $event, monthInfo.daysCount)"
                          />
                          <ShiftSequence
                              v-bind:shifts="shifts"
                              v-bind:shift_seq_rules="shift_seq_rules"
                              @set-shift-seq-rules="shift.setSeqRules($data, $event) ? setUnsavedChanges() : null"
                          />
                      </b-nav>
                  </b-card-text>
                </b-tab>
                <b-tab title="Справка">
                  <Tutorial />
                </b-tab>
              </b-tabs>
            </b-card>

            <b-card
                v-if="!withHoursMan"
                header="Заполнить автоматически"
                class="text-center"
            >
              <b-card-text>
                <!-- За статусом hasChanges эти методы следят самостоятельно:  -->
                <PlanEditorRunButton
                  v-bind:report="report"
                  v-bind:isUpdateMode="call_method === 'UPDATE'"
                  v-bind:isAnonymous="call_method === undefined"
                  v-bind:hasChanges="hasChanges"
                  v-bind:horizon="horizon"
                  v-bind:daysCount="monthInfo.daysCount"
                  v-bind:prevPlan="prev_plan"
                  @set-horizon="runner.setHorizon($data, $event)"
                  @fill-scheduler="runner.runScheduler($data, 'FILL')"
                />
              </b-card-text>
          </b-card>
        </b-card-group>
      </div>
      <PlanEditorSaveButton
        v-bind:report="report"
        v-bind:isUpdateMode="call_method === 'UPDATE'"
        v-bind:isAnonymous="call_method === undefined"
        v-bind:hasChanges="hasChanges"
        v-bind:revisions="revisions"
        @force-to-signup="$emit('force-to-signup')"
        @save-scheduler="runner.runScheduler($data, 'SAVE')"
      />
    </div>
  </div>
</template>

<script>
import PlanEditorTable from './plan_editor/EmpTable';
import PlanEditorHeader from './plan_editor/Header';
import StatusButtons from './plan_editor/StatusButtons';
import PlanEditorRunButton from './plan_editor/RunButton';
import WorkPatternGlobal from "./plan_editor/WorkPatternGlobal";
import Tutorial from "./Tutorial";


import runner from '../model/PlanRunner';
import emptable from '../model/PlanEmpTable';
import demand from '../model/PlanDemand';
import shift from '../model/PlanShifts';
import months from '../model/PlanMonth';
import pattern from '../model/PlanEmpPattern';
import hoursMan from '../model/HoursMan';

import PlanEditorSaveButton from "./plan_editor/SaveButton";
import WeeklyDemand from "./plan_editor/WeeklyDemand";
import ShiftList from "./plan_editor/ShiftList";
import ShiftSequence from "./plan_editor/ShiftSequence";


export default {
  name: 'PlanEditor',
  props: ['init', 'wizardFields', 'title', 'unsavingCause'],
  data: function () {
    let monthInfo = months.newState(this.init, this.wizardFields);
    let offsetHorizon = (monthInfo.month === monthInfo.currentMonth && monthInfo.endDay !== monthInfo.currentDay);
    return {
      validate: true,  // Нужна ли валидация во время правки  FIXME: false by default
      hasChanges: !!this.unsavingCause,  // Внесены ли несохранённые изменения
      prepopulated: !!this.unsavingCause,

      planTitle: (this.init && this.init.title) || '',
      // (дальше в каментах указаны ключи, которые добавляет каждая из моделей)
      ...hoursMan.newState(this.init, this.wizardFields),  // withHoursMan, usedHourIntervals
      monthInfo, // month, currentMonth, daysCount, weekends, weekdayNames, holyAdd, holyRemove, firstWeekDay
      ...emptable.newState(this.init, this.wizardFields, monthInfo.daysCount),  // employees, selected_count, last_emp_id
      ...runner.newState(this.init),  // report, call_method, prev_plan
      ...pattern.newState(this.init), // empPattern
      ...shift.newState(this.init, monthInfo.daysCount),  // shifts, shift_seq_rules
      horizon: {  // От какого до какого числа составлять план автоматикой
        from_day: (offsetHorizon ? (monthInfo.currentDay + 1) : 1),  // one based
        to_day: null,  // one based
      },
      revisions: this.init && this.init.revisions,  // url, count
    };
  },
  components: {
    ShiftSequence,
    ShiftList,
    PlanEditorSaveButton,
    PlanEditorTable,
    PlanEditorHeader,
    StatusButtons,
    PlanEditorRunButton,
    WorkPatternGlobal,
    Tutorial,
    WeeklyDemand,
  },
  methods: {
    setUnsavedChanges() {
      this.hasChanges = true;
      if (this.report.status === 'success') {
        this.report.save_message = '';
        this.report.run_message = '';
      }  // TODO: status === 'error' проверять, какие ошибки уже исправлены
      this.report.err_codes.splice(0);
    },
    getRequestData() {
      return runner.getSchedulerData(this.$data);
    },
    setPlanTitle(newTitle) {
      newTitle = newTitle.trim()
      if (this.planTitle !== newTitle) {
          this.setUnsavedChanges();
          this.planTitle = newTitle;
      }
    },
    addEmployee(count) {
      this.setUnsavedChanges();
      for (let i=0; i < count; i++) {
        emptable.addEmployee(this.$data);
      }
    }
  },
  computed: {
    shiftCount() {
        return this.shifts.length;
    },
    hourSlices() {
        if (this.withHoursMan) {
            return hoursMan.makeSlices(this.employees, this.monthInfo.daysCount);
        } else {
            return null;
        }
    },
    shiftHours() {
        if (typeof(this.shifts[0].duration) === 'number') {
            return this.shifts.map((sh) => sh.duration);
        } else {
            return null;
        }
    },
    // Импортированные модули, замаскированные под свойства
    emptable() {
      return emptable;
    },
    runner() {
      return runner;
    },
    demand() {
      return demand;
    },
    months() {
      return months;
    },
    shift() {
      return shift;
    },
    hoursMan() {
      return hoursMan;
    }
  },
}
</script>

