import {INIT_EMP_COUNT} from './PlanInit';
import {trackEvent} from './EventLog';
import {codeToStatus, updateCell} from "../lib/shift_statuses";
import EmpPattern from "./PlanEmpPattern";

export default {
    newState(init, wizardFields, daysCnt) {
        let empCnt = INIT_EMP_COUNT;
        if (wizardFields && wizardFields.empCnt) {
            empCnt = wizardFields.empCnt
        } else if (init) {
            empCnt = init.employees.length;
        }

        return {
            employees: Array.from(  // Сотрудники. У каждого есть имя и по одной смене на каждый день месяца
                {length: empCnt},
                (_, idx) => this.initEmployee(idx, init, daysCnt)
            ),
            selected_count: 0,  // Сколько ячеек сейчас выделено
            last_emp_id: empCnt,  // Самый большой локальный идентификатор сотрудника
        }
    },

    newShifts(emprow, daysCnt) {
        let shifts = Array.from(
            {length: daysCnt},
            () => {
                return {status: 'free', selected: false}
            });
        if (emprow !== null) {
            emprow.forEach((code, idx) => {
                shifts[idx] = {status: codeToStatus(code), selected: false}
            });
        }
        return shifts;
    },

    // CELLS
    switchShiftSelection(state, event) {
        const emp = this.findEmp(state, event[0]);
        const shift = emp.shifts[event[1]];
        shift.selected = !shift.selected;
        if (shift.selected) {
            state.selected_count += 1;
        } else {
            state.selected_count -= 1;
        }
    },

    setStatusForSelected(state, event) {
        let amends_cnt = 0;  // Сколько правок получилось
        for (let emp of state.employees) {
            for (let shift of emp.shifts) {
                if (shift.selected) {
                    shift.selected = false;
                    if (updateCell(shift, event)) {
                        amends_cnt += 1;
                    }
                }
            }
        }
        if (state.selected_count === 0) {
            alert('Ни одного дня не выбрано');
        } else if (amends_cnt === 0 && event === 'free') {
            // Все ячейки изначально 'free', поэтому такая ситуация может означать непонимание интерфейса
            // Другие же статусы могут нажиматься осознанно, чтобы сбросить выделение
            alert('Все выделенные дни и так свободны');
        }
        trackEvent('cell_status:set',
            {status: event, amends_cnt, selected_cnt: state.selected_count});
        state.selected_count = 0;
        return amends_cnt > 0;
    },

    renameMultiStatuses(state, renaming) {
        for (let emp of state.employees) {
            for (let shift of emp.shifts) {
                if (typeof(shift.status) === 'number') {
                    shift.status = renaming[shift.status] || 0;
                } else if (typeof(shift.status) === 'object') {
                    let new_idx =  renaming[shift.status.shift_idx]
                    if (!new_idx) {  // FIXME(?) What if must work at unknown shift_idx?
                        shift.status.is_forced = false;
                    }
                    shift.status.shift_idx = new_idx || 0;

                    let forbidden = [];
                    for (let old_idx of shift.status.forbidden) {
                        if (old_idx === 0) { // FIXME(?) What if this is the last option?
                            forbidden.push(0);
                        } else {
                            let new_idx = renaming[old_idx]
                            if (new_idx) {
                                forbidden.push(new_idx);
                            }
                        }
                    }
                    shift.status.forbidden = forbidden;
                } else if (typeof(shift.status) === 'string') {
                    if (shift.status === 'working' || shift.status === 'forced') {
                        if (!renaming[1]) {  // Эта смена может только удалиться. Переименоваться не может.
                            shift.status = 'free'
                        }
                    }
                } else {
                    console.error('Unknown shift status type:', typeof(shift.status), shift.status);
                }
            }
        }
    },

    reorderShiftPrios(state, renaming, newShiftsCount) {
        for (let emp of state.employees) {
            if (emp.shift_prio === null) {
                continue;
            } else if (newShiftsCount === 1) {
                emp.shift_prio = null;
                continue;
            }
            let newPrios = Array.from(
                {length: newShiftsCount},
                () => { return 'allow'; });
            for (let [idx, prio] of emp.shift_prio.entries()) {
                let newIdx = renaming[idx+1];
                if (newIdx) {
                    newPrios[newIdx-1] = prio;
                }
            }
            emp.shift_prio = newPrios;
        }
    },

    setStatusForAll(state, event) {
        if (!confirm('Очистить все ячейки, даже не выделенные?')) {
            // FIXME: Пока что event всегда free. Но теоретически мог бы быть и другим.
            trackEvent('clear_plan', {'confirmed': false});
            return false;
        }
        let amends_cnt = 0;
        for (let emp of state.employees) {
            for (let shift of emp.shifts) {
                shift.selected = false;
                if (shift.status !== event) {
                    amends_cnt++;
                    shift.status = event;
                }
            }
        }
        trackEvent('cell_status:clear_all',
            {'confirmed': true, amends_cnt, selected_cnt: state.selected_count});

        state.selected_count = 0;
        return amends_cnt > 0;
    },

    // EMPLOYEES
    findEmp(state, local_id) {
        return state.employees.find((el) => el.local_id === local_id);
    },
    addEmployee(state) {
        state.last_emp_id++;
        const emp = this.initEmployee(state.last_emp_id, null,
            state.monthInfo.daysCount);
        state.employees.push(emp);
    },
    initEmployee(local_id, init, daysCnt) {
        let empProps = init ? init.employees[local_id] : undefined;
        return {
            'name': empProps ? empProps.name : '',
            'rate': empProps ? empProps.rate : 1.0,
            'local_id': local_id,
            'deleted': false,  // Нужно для компонент следящих за сотрудником, который может быть удалён из списка
            'shifts': this.newShifts(init ? init.emptable[local_id] : null, daysCnt),
            'pattern': EmpPattern.fromApi(empProps && empProps.pattern, false),
            'shift_prio': (empProps && empProps.shift_prio) || null,  // TODO(?) Не нужен ли осмысленный дефолт?
        }
    },
    removeEmployee(state, local_id) {
        let event = {}
        if (state.employees.length === 1) {
            event['locked_last'] = true;
            alert('Без сотрудников планировать будет нечего');
            trackEvent('remove_employee', event);
            return false;
        }
        if (confirm('Удалить сотрудника?')) {
            event['confirmed'] = true;
            for (let [idx, emp] of state.employees.entries()) {
                if (emp.local_id === local_id) {
                    event['emp_idx'] = idx;
                    emp.deleted = true;
                    state.employees.splice(idx, 1);
                    break;
                }
            }
        } else {
            event['confirmed'] = false;
        }
        trackEvent('remove_employee', event);
        return event['confirmed'];
    },
}
