<template>
<input
    v-if="field.inputType === 'hidden'"
    type="hidden"
    :value="field.value"
/>

<b-row
        style="margin-bottom: 1rem;"
        v-else-if="field.inputType === 'checkbox'"
>
    <b-col md="3"></b-col>
    <b-col md="7">
        <b-form-checkbox
            v-model="field.value"
            :name="field.name"
        >
            {{ field.label }}
        </b-form-checkbox>
    </b-col>
</b-row>

<b-form-group
    v-else
    :description="field.helpText"
    :label="field.label"
    :label-for="'id_' + field.name"
    :label-cols-md="field.inputType === 'checkbox' ? 8 : 3"
    :state="!field.errors"
    :invalid-feedback="field.errors.join('; ')"
>
    <b-form-input
        :type="field.inputType"
        :name="field.name"
        :placeholder="field.placeholder || field.label"
        required
        :id="'id_' + field.name"
        cols-md="9"
        v-model="field.value"
        @keyup.enter.native="$emit('submit')"
    />
</b-form-group>
</template>

<script>
export default {
    name: "AuthFormField",
    props: ['field'],
}
</script>
