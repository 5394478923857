<template>
<tbody class="tbody_shifts">
  <tr :class="['shifts']">
    <td colspan="3" class="td_total_shift">
        Получилось сотрудников:
    </td>

    <td
      v-for="(day, idx) in totals"
      :key="idx"
      :class="[
        'td_total_empl',
        {'holy-start': holyStarts.includes(idx+1),
         'holy-end': holyEnds.includes(idx+1),
         }]"
    >
        <table class="hour_totals">
            <tr>
                <td
                    v-for="(t, idx) of day"
                    :key="idx">
                    {{ t }}
                </td>
            </tr>
        </table>
    </td>
    <td class="td_total_shift" colspan="2"></td>
  </tr>
</tbody>

</template>

<script>
import hoursMan from "../../model/HoursMan";


export default {
  name: "PlanEditorHourTotalRow",
  props: ['daysCount', 'holyStarts', 'holyEnds', 'employees', 'hourSlices'],
  components: {
  },
  data: function () {
    return {}
  },
  methods: {
  },
  computed: {
    totals() {
        return hoursMan.slicedTotals(this.hourSlices, this.employees);
    },
  },
}
</script>

