<template>
<b-row>
    <b-col
        v-for="btn in shiftButtonOrder"
        :key="btn.key"
        md="6" sm="12"
        class="status_btn_col"
    >
        <template v-if="withHoursMan && btn.unfold_hours">
            <b-button
                :id="'status-setter-' + btn.key"
                :class="[btn.btn_class, 'btn-block']"
                block
            >
                {{ btn.title }} &#x25BC;
            </b-button>
            <b-popover
                :target="'#status-setter-' + btn.key"
                placement="auto"
                triggers="click blur"
            >
                <b-input-group size="sm" :prepend="hours.start.name" class="mb-2">
                    <b-form-input
                        size="sm"
                        v-model="hours.start.val"
                        type="number" :min="hours.start.min" :max="hours.start.max" />
                </b-input-group>

                <b-input-group size="sm" :prepend="hours.end.name" class="mb-2">
                    <b-form-input
                        size="sm"
                        v-model="hours.end.val"
                        type="number" :min="hours.end.min" :max="hours.end.max" />
                </b-input-group>

                <b-form-invalid-feedback :state="!hours.err">{{ hours.err }}</b-form-invalid-feedback>

                <b-button @click="setHours(btn.key)" size="sm" class="btn btn_default">Ok</b-button>

                <div v-if="usedHourIntervals && usedHourIntervals.length">
                    <b-button
                        variant="link"
                        v-for="(interval, idx) of usedHourIntervals"
                        :key=idx
                        @click="setHours(btn.key, interval)"
                    >
                        {{ intervalDisplay(interval) }}
                    </b-button>
                </div>
            </b-popover>
        </template>

        <b-dropdown
            v-else-if="isMulti && btn.unfold_multi === 'dropdown'"
            :text="btn.title"
            class="btn-block"
            block
            :toggle-class="btn.btn_class"
            boundary="window"
        >
            <b-dropdown-item
                v-for="(sh, idx) in shifts"
                :key="idx"
                @click="$emit('set-status-for-selected', {action: btn.key, sh_idx: idx+1})"
            >
              {{ shiftName(sh, idx+1) }}
            </b-dropdown-item>
        </b-dropdown>

        <b-button-group
            v-else-if="isMulti && btn.unfold_multi === 'part_rest'"
            class="btn-block"
        >
          <b-button
              :class="btn.btn_class"
              @click="$emit('set-status-for-selected', btn.key)"
              block
          >{{ btn.title }}</b-button>

          <b-button
              :class="btn.btn_class"
              @click="showPartRest"
          >
              <i class="fas fa-caret-down"></i>
          </b-button>

          <b-modal
              title="Не будет работать:"
              id="part-rest"
              size="sm"
          >
            <b-checkbox
                v-for="(sh, idx) in forbiddenShifts"
                :key="idx"
                class="forbidden_shift"
                v-model="sh.checked"
            >
              {{ sh.title }}
            </b-checkbox>
              <br>
            <p class="help_text">
                <i class="fas fa-question-circle"></i>
                Выбранные смены станут недоступными для работы.<br/>
                Невыбранные - будут доступны.<br/>
                Чтобы посмотреть какие смены в ячейке таблицы недоступны, нужно кликнуть по ней правой кнопкой.<br/>
                <a href="/tutorial/shift_statuses#forbidden-list" target="_blank">Подробности...</a>
            </p>
            <template v-slot:modal-footer="{ close }">
                <b-button
                  class="btn_default"
                  @click="close(); emitForbidden()"
                >
                  Ок
                </b-button>
                <b-button
                  variant="light"
                  @click="close()"
                >
                  Отмена
                </b-button>
            </template>
          </b-modal>
        </b-button-group>

        <b-button
            v-else
            :class="btn.btn_class"
            @click="$emit('set-status-for-selected', btn.key)"
            block
        >
            {{ btn.title }}
        </b-button>
    </b-col>
</b-row>
</template>

<script>
import plan_shifts from '../../model/PlanShifts';
import {shiftStatuses} from '../../lib/shift_statuses';
import {modalTracker} from "../../model/EventLog";  // trackEvent
import {setLocationHash} from "../../lib/nav";

export default {
    name: "StatusButtons",
    props: ['isMulti', 'shifts', 'withHoursMan', 'usedHourIntervals', 'selectedCount'],
    data() {
        return {
            shiftButtonOrder: [
                shiftStatuses['rest'], shiftStatuses['forced'],
                shiftStatuses['vacation'], shiftStatuses['free']
            ],
            hours: {
              start: {val: '9', min: 0, max: 23, name: 'Начало'},
              end: {val: '21', min: 1, max: 24, name: 'Окончание'},
              err: '',
            },
            forbiddenShifts: [],
        }
    },
    methods: {
        setHours(key, interval) {
            this.hours.err = '';
            let start, end;
            if (interval) {
                start = interval[0];
                end = interval[1];
            } else {
                end = this.getHourValue(this.hours.end);
                start = this.getHourValue(this.hours.start);
            }
            if (this.hours.err) {
                return;
            }
            this.$root.$emit('bv::hide::popover');
            this.$emit('set-status-for-selected', {action: key, start, end});
            if (!interval) {
                this.$emit('add-hour-interval-history', [start, end]);
            }
        },
        getHourValue(field) {
            let rawValue = field.val.replace(/[^0-9]+/g, '');
            if (rawValue.match(/^0+$/g) !== null) {
                if (field.min > 0) {
                    this.hours.err = this.fieldRangeErrMsg(field);
                }
                return 0;
            }
            rawValue = rawValue.replace(/^0+/g, '')
            let val = parseInt(rawValue);
            if (isNaN(val)) {
                this.hours.err = field.name + ' должно быть числом';
                return;
            }
            if (val < field.min || val > field.max) {
                this.hours.err = this.fieldRangeErrMsg(field);
                return;
            }
            return val;
        },
        fieldRangeErrMsg(field) {
            return field.name + ' должно быть между ' + field.min + ' и ' + field.max + ' часами';
        },
        intervalDisplay(interval) {
            let start = interval[0] < 10 ? '0' + interval[0] : interval[0];
            let end = interval[1] < 10 ? '0' + interval[1] : interval[1];
            return start + '-' + end;
        },
        shiftName: plan_shifts.shiftName,

        // Partial Rest
        showPartRest(event) {
          setLocationHash('#part-rest');
          if (this.preShowPartRest(event)) {
            this.onShowPartRest();
            this.$bvModal.show('part-rest');
          }
        },

        preShowPartRest(event) {
            if (this.selectedCount === 0) {
              alert('Ни одного дня не выбрано');
              // FIXME: trackEvent('cell_status:set',
              //      {status: event, amends_cnt, selected_cnt: state.selected_count});
              event.preventDefault();
              return false;
            }
            return true;
        },
        onShowPartRest() {
            modalTracker.setField('shift_cnt', this.shifts.length);
            modalTracker.setField('selected_cnt', this.selectedCount);
            this.forbiddenShifts.splice(0, this.forbiddenShifts.length,
              ...this.shifts.map((sh, idx) => {
                return {
                  title: this.shiftName(sh, idx+1),
                  checked: false,  // TODO: Вычислять дефолт на основании выделенных ячеек
                }
              }));
        },
        emitForbidden() {
          let list = [];
          this.forbiddenShifts.forEach(function (sh, idx) {
            if (sh.checked) {
              list.push(idx+1);
            }
          });
          if (list.length === this.shifts.length) {
            this.$emit('set-status-for-selected', 'rest');
          } else {
            this.$emit('set-status-for-selected',
                {action: 'rest', shifts: list});
          }

        },
    }
}
</script>

<style scoped>
</style>
