<template>
    <b-nav-item
        class="dashed_link"
        href="#weekly-demand"
        @click="showForm"
    >
        <i class="fas fa-user-friends"></i>&nbsp;
        <span>Количество сотрудников в смене</span>

        <b-modal
            id="weekly-demand"
            size="lg"
            title="Количество сотрудников по дням недели"
            @shown="loadDemands"
        >
            <WeeklyDemandTab
                v-if="tabs.length === 1"
                @apply-cell="applySingleCell($event.cell, $event.sh_idx, true)"
                :wd="tabs[0]"
            />
            <b-tabs
                v-else
            >
                <b-tab
                    v-for="wd in tabs"
                    :key="wd.sh_idx"
                    :title="'Смена ' + wd.sh_idx"
                >
                    <WeeklyDemandTab
                        @apply-cell="applySingleCell($event.cell, $event.sh_idx, true)"
                        :wd="wd" />
                </b-tab>
            </b-tabs>
            <template v-slot:modal-footer="{ close }">
                <b-button
                    variant=""
                    size=""
                    :class="['btn', 'btn_default']"
                    @click="applyAllCells"
                    v-if="hasPending"
                >
                    {{ hasPending }}
                </b-button>
                <b-button
                    variant="light"
                    size=""
                    class="btn"
                    @click="close()"
                >
                    Закрыть
                </b-button>
            </template>
        </b-modal>
    </b-nav-item>
</template>

<script>
import {setLocationHash} from '../../lib/nav'
// import model from "../model/PlanDemand";
import WeeklyDemandTab from "./WeeklyDemandTab";


export default {
    name: 'WeeklyDemand',
    components: {WeeklyDemandTab},
    props: ['shifts'],  // TODO: week_demands for multi shifts
    data: function () {
        return {
            tabs: this.shifts.map(this.makeTabData.bind(this)),
            propagations: {
                'all': ['workdays', 'holidays', 0, 1, 2, 3, 4, 5, 6],
                'workdays': [0, 1, 2, 3, 4],
                'holidays': [5, 6],
            },
        }
    },
    methods: {
        makeTabData(shift, idx) {
            let wd = shift.week_demands;
            function init_batch(title, key) {
                let val = wd.has(String(key)) ? wd.get(String(key)) : NaN;
                return {title: title, key: key, applied: false, rewrited: null, val: val}
            }
            return {
                sh_idx: idx + 1,  // sh_idx нумеруются с единицы
                title: shift.title,
                batchedAll:
                    [init_batch('Все дни', 'all')],
                batchedHalf: [
                    init_batch('Будни', 'workdays'),
                    init_batch('Выходные', 'holidays')],
                batchedDays: [
                    init_batch('Понедельники', 0),
                    init_batch('Вторники', 1),
                    init_batch('Среды', 2),
                    init_batch('Четверги', 3),
                    init_batch('Пятницы', 4),
                    init_batch('Субботы', 5),
                    init_batch('Воскресенья', 6)],
            }
        },
        showForm(event) {
            event.preventDefault();
            setLocationHash('#weekly-demand');
            this.$bvModal.show('weekly-demand');
            // let self = this;
            // this.$nextTick(() => self.loadDemands());
        },
        loadDemands() {
            this.tabs = this.shifts.map(this.makeTabData.bind(this));
            // TODO: compare with daily demands
        },
        applyAllCells() {
            let sh_idx = 1;
            for (let t of this.tabs) {
                for (let b of [t.batchedAll, t.batchedHalf, t.batchedDays]) {
                    for (let cell of b) {
                        if (!cell.applied) {
                            this.applySingleCell(cell, sh_idx, false);
                        }
                    }
                }
                sh_idx++;
            }
        },
        applySingleCell(cell, sh_idx, dominant) {
            // dominant - должно ли применение перезаписывать решения других ячеек
            //   (используется при нажатии "ok" возле конкретной ячейки)
            if (!isNaN(cell.val)) {
                this.$emit('batch-change-demand',
                    {'daysType': cell.key, 'value': cell.val, 'sh_idx': sh_idx});
                cell.applied = true;
                cell.rewrited = null;
                this.propagateDemand({
                    'daysType': cell.key,
                    'value': cell.val,
                    'title': cell.title.toLowerCase(),
                    'dominant': dominant,
                    'sh_idx': sh_idx,
                });
            }
        },
        propagateDemand(event) {
            // Если проставлено число для объединения дней недели, то проставлять в более специфичные ячейки
            let keys = this.propagations[event.daysType];
            let stopped = [];
            if (keys) {
                let t = this.tabs[event.sh_idx - 1];
                for (let b of [t.batchedAll, t.batchedHalf, t.batchedDays]) {
                    for (let cell of b) {
                        if (keys.includes(cell.key) && !stopped.includes(cell.key)) {
                            let cellVal = cell.val;
                            if (!isNaN(cellVal) && cellVal !== event.value) {
                                if (event.dominant) {  // Нажата одна кнопка и должна перезаписать всё, что видит
                                    // Мы перезаписали значение ячейки и сейчас оно не совпадает с EmpTable:
                                    cell.applied = false;
                                    cell.rewrited = event;
                                } else {
                                    let subcells = this.propagations[cell.key];
                                    if (subcells) {
                                        stopped.push(...subcells);
                                    }
                                }
                            } else {
                                cell.val = event.value;
                                cell.applied = true;
                                cell.rewrited = null;
                            }
                        }
                    }
                }
            }
        },
    },
    computed: {
        hasPending() {
            let firstFound = false;
            for (let t of this.tabs) {
                for (let b of [t.batchedAll, t.batchedHalf, t.batchedDays]) {
                    for (let sel of b) {
                        if (!sel.applied && !isNaN(sel.val)) {
                            if (firstFound) {
                                return 'Применить всё';
                            } else {
                                firstFound = 'Применить';
                            }
                        }
                    }
                }
            }
            return firstFound;
        },
    }
}
</script>
