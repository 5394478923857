<template>
<b-container>
    <AuthFormField v-bind:field="fields.login" @submit="sendRequest" />
    <AuthFormField v-bind:field="fields.password" @submit="sendRequest" />
    <AuthFormField v-if="hasPlanChanges" v-bind:field="fields.need_save" />
    <AuthFormField v-if="hasPlanChanges" v-bind:field="fields.plan_data" />

    <b-row
        style="margin-bottom: 1rem;"
        v-if="errors.length"
        class="error"
    >
        <b-col md="3"></b-col>
        <b-col md="9">
            <i class="fas fa-exclamation-circle"></i>
            <span> {{ errors.join('; ') }}</span>
        </b-col>
    </b-row>

    <b-row>
        <b-col md="3"></b-col>
        <b-col md="3">
            <b-button class="btn_default" @click="sendRequest" :disabled="inProgress">
                Войти
            </b-button>
        </b-col>
        <b-col md="6">
            <b-link class="" target="_blank" href="/accounts/password/reset/">
                Забыли пароль?
            </b-link>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
import {sendAuthRequest} from "../../model/AuthAPI";
import AuthFormField from './FormField';

export default {
    name: 'AuthFormLogin',
    components: {AuthFormField},
    props: ['hasPlanChanges', 'planData'],
    data() {
        return {
            inProgress: false,
            errors: [],
            fields: {
                login: {
                    name: 'login',
                    label: 'E-mail',
                    placeholder: 'E-mail адрес',
                    inputType: 'email',
                    errors: [],
                    value: '',
                },
                password: {
                    name: 'password',
                    label: 'Пароль',
                    inputType: 'password',
                    errors: [],
                    value: '',
                },
                need_save: {
                    name: 'need_save',
                    label: 'Сохранить составленный план в кабинет',
                    inputType: 'checkbox',
                    errors: [],
                    value: true,
                },
                plan_data: {
                    name: 'plan_data',
                    inputType: 'hidden',
                    value: this.planData,
                },
            }
        }
    },
    methods: {
        sendRequest() {
            this.inProgress = true;
            sendAuthRequest(
                '/accounts/login/',
                this,
                this.onSuccess.bind(this),
                'Не удалось залогиниться. Попробуйте чуть позже.'
            );
        },
        onSuccess(response) {
            if (this.planData) {
              // Не удалось добиться от allauth нормальной обработки ImmediateResponse.
              // Настоящий ответ пишется сериализованным в поле html
              if (response.data && response.data.html && response.data.html[0] === '{') {
                let realResponse = JSON.parse(response.data.html);
                if (realResponse.error && realResponse.message) {
                  this.$emit('after-login-save-error', realResponse);
                  return;
                }
              }
              this.$emit('force-redirect', '/my/schedule/list/');
            } else {
                this.$emit('force-reload');
            }
        },
    }
}
</script>
