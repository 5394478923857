var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{class:[
      'cell_w_h',
       (!_vm.statusSlices || _vm.statusSlices[_vm.day_idx] === null) ? _vm.dayProps.cell_class : 'cell_free',
       {'selected': _vm.shift.selected,
        'holy-start': _vm.holyStarts,
        'holy-end': _vm.holyEnds,
        'cell_part_rest': _vm.hasForbidden,
        }],on:{"click":function($event){!_vm.readOnly && _vm.$parent.$parent.$emit('switch-shift-selection', [_vm.emp_local_id, _vm.day_idx])},"contextmenu":function($event){$event.preventDefault();return _vm.showForbiddenShifts($event)}}},[(_vm.statusSlices && _vm.statusSlices[_vm.day_idx] !== null)?_c('table',{staticClass:"hour_statuses"},[_c('tr',_vm._l((_vm.statusSlices[_vm.day_idx]),function(s,s_idx){return _c('td',{key:s_idx,class:'cell_' + s.status})}),0)]):[_vm._v("\n    "+_vm._s(_vm.shiftLetter)+"\n  ")],_c('b-link',{ref:"promptTarget",attrs:{"id":_vm.promptTrgId},on:{"focusout":function($event){_vm.promptTrgContent = ''}}},[_vm._v(_vm._s(_vm.promptTrgContent))])],2)}
var staticRenderFns = []

export { render, staticRenderFns }