<template>
<b-modal
    id="edit-employee"
    title="Данные о сотруднике"
    size="md"
    @ok="onOk"
    @hidden="emp = null /* Чтобы WorkPatternWidget при повторном открытии перерисовывался и не терялся из $refs */"
>
    <template v-slot:default="{ ok }">
        <b-form class="empl_form">
            <b-form-group
                label="Имя"
                label-class="label_violet"
                label-for="id_username"
                label-cols-sm="4"
            >
                <b-form-input
                    id="id_username"
                    type="text"
                    size="sm"
                    v-model="name"
                    @keyup.native.enter="ok()"
                ></b-form-input>
            </b-form-group>

            <hr/>
            <b-form-group
                label="Ставка"
                label-class="label_violet"
                label-for="id_rate"
                label-cols-sm="4"
                :state="!rate_err"
            >
                <template
                    slot="invalid-feedback"
                >
                    <div class="error_text">{{ rate_err }}</div>
                </template>
                <p class="help_text">
                    <i class="fas fa-exclamation-circle"></i>
                    Используется для вычисления количества рабочих смен при автоматическом составлении графика.
                </p>
                <b-row>
                    <b-col md="4" sm="4">
                        <b-form-input
                            id="id_rate"
                            type="text"
                            size="sm"
                            v-model="rate"
                            @keyup.native.enter="ok()"
                        ></b-form-input>
                    </b-col>
                    <b-col md="8" sm="8"><p class="help_text">* примеры: 0.5, 1, 1/3</p></b-col>
                </b-row>
            </b-form-group>

            <hr/>
            <b-form-group
                label="Периодичность работы"
                label-class="label_violet"
                label-cols-sm="4"
            >
                <p class="help_text">
                    <i class="fas fa-exclamation-circle"></i>
                    Условия для всех заданы в общих настройках.
                </p>
                <b-checkbox v-model="std_pattern">
                    Как у всех
                </b-checkbox>
                <p
                    class="help_text"
                    v-if="std_pattern"
                >
                    * сняв галку, можно настроить периодичность для этого сотрудника
                </p>
            </b-form-group>

            <b-form-group
                v-if="emp !== null && !std_pattern"
                label="Индивидуальные пожелания"
                label-class="label_gray"
            >
                <p class="help_text">
                    <i class="fas fa-exclamation-circle"></i>
                    Любое из полей можно оставлять пустым, что означает отсутствие ограничений.
                </p>
                <WorkPatternWidget
                    ref="work_pattern"
                    :stored="emp.pattern"
                    :daysCount="daysCount"
                />
    <!--        <b-form-group-->
    <!--                label="Доступность"-->
    <!--                label-for="id_access"-->
    <!--        >-->
    <!--            <b-row>-->
    <!--                <b-col>-->
    <!--   TODO: use EmpTableRow                 <table>-->
    <!--                        <thead>-->
    <!--                        <tr>-->
    <!--                            <th>пн</th>-->
    <!--                            <th>вт</th>-->
    <!--                            <th>ср</th>-->
    <!--                            <th>чт</th>-->
    <!--                            <th>пт</th>-->
    <!--                            <th>сб</th>-->
    <!--                            <th>вскр</th>-->
    <!--                        </tr>-->
    <!--                        </thead>-->
    <!--                    </table>-->
    <!--                </b-col>-->
    <!--            </b-row>-->
    <!--        </b-form-group>-->
            </b-form-group>

            <template v-if="shift_prio">
              <hr/>
              <b-form-group
                label="Предпочтения по сменам"
                label-class="label_violet"
                label-cols-sm="4"
              >
                <b-link v-b-toggle.collapse-shift-prio
                  class="dashed_link">
                    <span>{{ prio_toggle_label }}</span>
                </b-link>
                &nbsp;
                <a target="_blank" href="/tutorial/emp_shift_priorities/#content-start">
                  <i class="fas fa-question-circle"></i>
                </a>
                <div v-if="allShiftsIsForbidden" class="error_text_right">
                  Указано, что все смены запрещены
                </div>
              </b-form-group>

              <b-form-group>
                  <b-collapse id="collapse-shift-prio" v-model="prio_is_visible" class="mt-2">
                      <b-form-group v-for="(sh, idx) in shifts" :key="idx"
                        :label="sh.title || 'Смена №' + (idx+1)"
                        label-class=""
                        label-cols-sm="7"
                        label-align-sm="right"
                      >
                        <b-form-select
                          size="sm"
                          v-model="shift_prio[idx]"
                          :options="prio_options"
                        />
                      </b-form-group>
                  </b-collapse>
              </b-form-group>
            </template>

            <hr/>
            <b-form-group
                label="Удалить сотрудника"
                label-class="label_violet"
                label-cols-sm="4"
            >
                <b-button
                    size="sm"
                    @click="$parent.$emit('remove-employee', local_id)"
                    class="btn_red"
                >
                    <i class="far fa-trash-alt"></i>
                </b-button>
            </b-form-group>
        </b-form>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
        <b-button
            class="btn_default"
            @click="ok"
            >
            Ок
        </b-button>
        <b-button
            variant="light"
            @click="cancel()"
            >
            Закрыть
        </b-button>
    </template>
</b-modal>
</template>

<script>
import {setIfChanged} from "../../lib/objects";
import {modalTracker} from "../../model/EventLog";

import WorkPatternWidget from "./WorkPatternWidget";

export default {
    name: 'EmpPropsForm',
    components: {WorkPatternWidget},
    props: ['daysCount', 'shiftCount', 'shifts'],
    data() {
        return {
            'emp': null,  // Модель сотрудника, которые редактируется в данный момент
            'name': '',
            'rate': 1.0,
            'rate_err': '',
            'local_id': null,
            'std_pattern': true,
            'shift_prio': null,
            'prio_options': PRIO_OPTIONS,
            'prio_is_visible': false,
        }
    },
    computed: {
        deleted() {
            return this.emp && this.emp.deleted;
        },
        hasSignificantPrios() {
            return this.shift_prio && this.shift_prio.some((el) => el !== 'allow');
        },
        prio_toggle_label() {
          if (this.prio_is_visible) {
            return 'Свернуть';
          } else if (this.hasSignificantPrios) {
            return 'Изменить';
          } else {
            return 'Выбрать'
          }
        },
        allShiftsIsForbidden() {
          return this.shift_prio !== null && this.shift_prio.every((opt) => {return opt === 'forbid'})
        }
    },
    methods: {
        loadEmpData(emp) {
            this.emp = emp;
            this.name = emp.name;
            this.rate = emp.rate ? String(emp.rate) : '1.0';
            if (this.rate.length > 5) {
                this.rate = this.toNatural(emp.rate);
            }
            this.rate_err = '';
            this.local_id = emp.local_id;

            this.std_pattern = emp.pattern.standard;
            if (!this.std_pattern) {
                let self = this;
                this.$nextTick(() => self.$refs.work_pattern.loadStored());
            }

            if (this.shiftCount > 1) {
                if (emp.shift_prio) {
                  this.shift_prio = Array.from(emp.shift_prio);
                }  else {
                  this.shift_prio = Array.from(
                      {length: this.shiftCount},
                      () => 'allow');
                }
            } else {
              this.shift_prio = null;
            }
            this.prio_is_visible = this.hasSignificantPrios;
        },
        showForEmp(emp) {
            window.location.hash = 'edit-employee';
            this.$bvModal.show('edit-employee');
            this.loadEmpData(emp);
            modalTracker.setField('local_id', this.local_id);
            modalTracker.setField('emp_name', this.name);
        },
        // Сохранение данных из формы
        onOk(event) {
            // TODO: сохранять в modalTracker изменившиеся поля
            let hasErrors = false;
            let hasChanges = false;

            let rate = this.cleanRate();
            hasErrors |= isNaN(rate);

            if (!this.std_pattern) {
                hasErrors |= !this.$refs.work_pattern.cleanInput();
            }

            if (hasErrors) {
                event.preventDefault();
            } else {
                hasChanges |= this.storeEmpData(rate);

                if (this.std_pattern) {
                    hasChanges |= setIfChanged(this.emp.pattern, 'standard', true);
                } else {
                    hasChanges |= setIfChanged(this.emp.pattern, 'standard', false);
                    hasChanges |= this.$refs.work_pattern.storeInput();
                }
            }

            if (hasChanges) {
                this.$parent.$emit('set-unsaved');
            }
        },
        cleanRate() {
            let rate;
            let parts = this.rate.replace(',', '.').replace(/\s/g, '').split('/', 2);
            if (parts.length === 2) {
                let num = Number(parts[0]);
                let den = Number(parts[1]);
                if (isNaN(num) || isNaN(den)) {
                    this.rate_err = 'Должна быть числом';
                    return NaN;
                } else {
                    rate = num / den;
                }
            } else {
                rate = Number(parts[0]);
            }

            if (isNaN(rate)) {
                this.rate_err = 'Должна быть числом';
                return NaN;
            } else if (rate <= 0.0) {
                this.rate_err = 'Должна быть больше нуля';
                return NaN;
            } else if (rate > 1.5) {
                this.rate_err = 'Должна быть не более 1.5';
                return NaN;
            }
            return rate;
        },
        toNatural(rate) {
            // Представляем ставку float в виде натуральной дроби
            // Подразумевается, что rate - от нуля до 1 невключительно
            const epsilon = 0.001;
            const max_denom = 50;
            let denominator = 1;
            let numerator = rate;
            let error = 1.0;

            // TODO: Будет ли это работать при rate > 1.0
            while (error > epsilon && denominator !== max_denom) {
                denominator++;
                numerator = rate * denominator;
                error = Math.abs(numerator - Math.round(numerator));
            }
            if (denominator === max_denom) {
                return rate.toFixed(3);
            } else {
                return String(Math.round(numerator)) + '/' + String(denominator);
            }
        },
        storeEmpData(rate) {
            let hasChanges = false;
            hasChanges |= setIfChanged(this.emp, 'name', this.name);
            hasChanges |= setIfChanged(this.emp, 'rate', rate, 1.0);

            if (this.shift_prio === null || this.shift_prio.every((opt) => {return opt === 'allow'})) {
              hasChanges |= setIfChanged(this.emp, 'shift_prio', null);
            } else {
              hasChanges |= setIfChanged(this.emp, 'shift_prio', this.shift_prio);
            }
            return hasChanges;
        },
    },
    watch: {
        deleted: function(newVal) {
            if (newVal) {
                this.$bvModal.hide('edit-employee');
            }
        }
    }
}

const PRIO_OPTIONS = [
  {text: 'Предпочтительно', value: 'prefer'},
  {text: 'Может', value: 'allow'},
  {text: 'Не может', value: 'forbid'},
]
</script>
