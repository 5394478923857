<template>
     <b-nav-item
        class="dashed_link"
        href="#multi-shift-settings"
        @click="showForm"
    >
        <i class="fas fa-list-ul"></i>&nbsp;&nbsp;
        <span>Список смен</span>

        <b-modal
            id="multi-shift-settings"
            title="Список смен"
            size="lg"
            @show="loadToLocal"
            @ok="onOk"
            class="empl_form"
        >
            <template slot="default">
                <p class="help_text">
                    <span v-if="isMulti">
                        <i class="fas fa-exclamation-circle"></i>
                        Названия (если заданы) будут фигурировать в легенде к графику.<br/>
                        <i class="fas fa-exclamation-circle"></i>
                        Длительности (если заданы) будут учитываться в подсчёте отработанных часов.<br/>
                    </span>
                    <span v-else>
                        <i class="fas fa-exclamation-circle"></i>
                        Сейчас у вас нет деления на смены. Считается, что все работают в "первую".<br/>
                        <i class="fas fa-exclamation-circle"></i>
                        Её длительность (если задана) будет учитываться в подсчёте отработанных часов.
                    </span>
                </p>
                <p class="help_text">
                    <a target="_blank" href="/tutorial/multi_shift/#content-start">Как пользоваться...</a>
                </p>

                <b-form-group
                    class="multi_shift_settings_form"
                >
                    <div class="row_add_button">
                        <b-row>
                            <b-col>
                                <b-button
                                    class="btn-sm btn_yellow"
                                    @click="addShift"
                                >
                                    <i class="fas fa-plus"></i>
                                    Добавить смену
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="row_setting"
                        v-for="(sh, idx) in local_shifts"
                        :key="sh.origin_id"
                    >
                        <b-row>
                            <b-col lg="1">
                                <div class="cell_idx">{{ idx+1 }}</div>
                            </b-col>
                            <b-col lg="4">
                                <b-input
                                    size="sm"
                                    v-model="sh.title"
                                    placeholder="название"
                                />
                            </b-col>
                            <b-col lg="2" class="cell_text">
                                <div class="">длительность:</div>
                            </b-col>
                            <b-col lg="3">
                                <b-input
                                    size="sm"
                                    v-model="sh.duration"
                                    type="number"
                                    :required="false"
                                    :trim="true"
                                    :min="0"
                                    :step="1"
                                    placeholder="часов"
                                />
                            </b-col>
                            <b-col lg="2" class="del_button">
                                <b-button
                                    v-if="isMulti"
                                    @click="removeShift(sh.origin_id)"
                                    size="sm"
                                    class="btn_red"
                                >
                                    <i class="far fa-trash-alt"></i>
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                </b-form-group>

                <b-form-invalid-feedback
                    v-if="errMsg"
                    class="error_text"
                    :state="!errMsg"
                >
                    {{ errMsg }}
                </b-form-invalid-feedback>
            </template>

            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button
                    class="btn_default"
                    @click="ok"
                >
                    Ок
                </b-button>
                <b-button
                    variant="light"
                    @click="cancel()"
                >
                    Отмена
                </b-button>
            </template>
        </b-modal>
    </b-nav-item>
</template>

<script>
import {findRemove} from "../../lib/arrays";
import {setLocationHash} from "../../lib/nav";

export default {
    name: "ShiftList",
    props: ["shifts"],
    data() {
      return {
          local_shifts: [],
          next_id: 0,
          errMsg: '',
      }
    },
    methods: {
        loadToLocal() {
            this.local_shifts = [];
            this.errMsg = '';
            this.shifts.forEach((sh, idx) => {
                this.local_shifts.push(
                    // origin_id начинается с единицы
                    {title: sh.title,
                     origin_id: idx+1,
                     duration: typeof(sh.duration) === 'number' ? sh.duration : null,
                    }
                )
            });
            this.next_id = this.local_shifts.length + 1;
        },
        addShift() {
            this.local_shifts.push({title: '', origin_id: this.next_id, duration: null});
            this.next_id += 1;
        },
        removeShift(origin_id) {
            if (confirm("Удаляем смену?")) {
                // TODO: Если это актуально, указывать так же на то, что связанные со сменой правила и уже назначенные статусы тоже удалятся
                findRemove(this.local_shifts, (sh) => sh.origin_id === origin_id);
            }
        },
        showForm(event) {
            event.preventDefault();
            setLocationHash('#multi-shift-settings');
            this.$bvModal.show('multi-shift-settings');
        },
        onOk(event) {
            this.errMsg = '';
            let has_hours = null;
            for (let sh of this.local_shifts) {
                let duration = sh.duration;
                if (duration !== null) {
                    if (typeof(duration) === 'string') {
                        duration = parseInt(duration.replace(/[^0-9]+/g, '').replace(/^0+/g, ''));
                    }

                    if (isNaN(duration)) {
                        duration = null;
                    }
                }
                if (duration === null ? has_hours === true : has_hours === false) {
                    this.errMsg = 'Длительность нужно задавать либо всем сменам, либо ни одной.\n' +
                        'Иначе, суммы не сосчитать.';
                    event.preventDefault();
                    return;
                } else {
                    has_hours = (duration !== null);
                    sh.duration = duration;
                }
            }

            this.$emit('applyShifts', this.local_shifts);
        }
    },
    computed: {
        isMulti() {
            return this.local_shifts.length > 1;
        },
    }
}
</script>

<style scoped>

</style>
