<template>
    <div class="container paddings_50">
        <h2>{{ title }}</h2>

        <p v-if="hasSchedules" class="text-center text-muted">
            <i class="fas fa-exclamation-circle"></i>&nbsp;
            <a :href="histListUrl">Клонировать планы из Истории</a> проще, чем создавать каждый раз заново.
        </p>

        <div class="row justify-content-lg-center steps_create_plan">
            <div class="col-md-3 col-sm border shadow rounded step_month_radio">
                <span class="badge badge_circle badge_lilac">1</span>
                <h5 class="text-left">Выберите месяц для нового плана</h5>
                <hr>
                <MonthList
                    v-bind:month-state="monthState"
                    v-bind:tmpl="null"
                />
            </div>

            <div class="col-md-3 col-sm border shadow rounded step_empl_cnt">
                <span class="badge badge_circle badge_lilac">2</span>
                <h5 class="text-left">Укажите общее количество сотрудников в плане</h5>
                <hr>
                <p>Сотрудников можно добавлять и удалять в редакторе, но удобнее сразу задать, сколько нужно:</p>
                <div class="container">
                    <div class="row">
                        <div class="col-lg col-md col-sm"></div>
                        <div class="col-lg-6 col-md-8 col-sm-8">
                            <b-form-input
                            v-model="empCnt"
                            type="text"
                            @keyup.enter.native="nextStep"
                            >
                            </b-form-input>
                        </div>
                        <div class="col-lg col-md col-sm"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm border shadow rounded step_to_scheduler">
                <span class="badge badge_circle badge_lilac">3</span>
                <h5 class="text-left">Переходите к редактору</h5>
                <hr>
                <p>В нём вы сможете указать:</p>
                <ul class="text-left">
                    <li>Требования по количеству человек в сменах</li>
                    <li>Имена сотрудников</li>
                    <li>Ставки сотрудников</li>
                    <li>Отпуски</li>
                    <li>Пожелания по рабочим и выходным дням</li>
                    <li>Пожелания по периодичности работы</li>
                </ul>
                <p>...и воспользоваться автоматикой, которая построит план по вашим условиям</p>

                <p v-if="canHoursMan">
                    <b-checkbox v-model="withHoursMan">Почасовой график</b-checkbox>
                </p>

                <p
                    v-if="error_msg"
                    class="error text-center"
                >
                    <i class="fas fa-exclamation-circle"
                       :style="'font-size: ' + errMotion.current + 'px'"
                    ></i>&nbsp;<nl2br tag="span" :text="error_msg" />
                </p>
                <b-button
                    class="btn_default"
                    @click="nextStep"
                >
                    Перейти к редактору
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import motions from '../lib/motions'
import months from '../model/PlanMonth';
import {trackEvent} from '../model/EventLog';
import {parseEmpCnt} from '../model/PlanCreation';
import MonthList from "./plan_editor/MonthList";

export default {
    name: "CreatePage",
    components: {MonthList},
    props: ['wizardFields', 'title'],
    data() {
        return {
            canHoursMan: window.FEATURES && window.FEATURES.includes('hours_man'),
            withHoursMan: false,  // Персональная фича для ручного задания часов работы
            monthState: months.newState(null),
            error_msg: '',
            errMotion: motions.newMotion([12, 12, 13, 13, 13, 14, 14, 15, 15, 15, 15, 14, 14, 13, 13, 13]),
            empCnt: '',
            hasSchedules: window.MY_URLS && window.MY_URLS.hasSchedules,
            histListUrl: window.MY_URLS && window.MY_URLS.histListUrl,
        };
    },
    methods: {
        nextStep() {
            this.error_msg = '';
            if (this.monthState.selected) {
                this.wizardFields.month = this.monthState.selected;
            } else {
                this.error_msg = 'Нужно выбрать месяц.';
                this.errMotion.run();
                return;
            }
            let empCnt = parseEmpCnt(this);
            if (this.error_msg) {
                this.errMotion.run();
                return;
            }
            this.wizardFields.empCnt = empCnt;
            this.wizardFields.withHoursMan = this.withHoursMan;

            trackEvent('wizard:create:passed',
                {'month': this.wizardFields.month, 'emp_cnt': empCnt});
            this.$emit('nextStep');
        },

    }
}
</script>

