import Cookie from "js-cookie";
import axios from "axios";

export default {
    makeUrl(method) {
        return window.MY_URLS.scheduleCall + method + '?guid=' + window.MY_URLS.guid;
    },
    makeRequest(method, options) {
        return {
            url: this.makeUrl(method),
            contentType: 'application/json; charset=utf-8',
            method: 'POST',
            timeout: 3000,
            headers: {"X-CSRFToken": Cookie.get('csrftoken')},
            data: options,
            dataType: "json"
        };
    },
    sendRequest(report, method, options, successCallback) {
        report.error = '';
        report.loading = true;
        axios(this.makeRequest(method, options))
            .then(successCallback)
            .catch(this.onError.bind(this, report));
    },
    onError(report, error) {
        try {
            if (!error.response) {
                report.error = 'Не удаётся выполнить. Проверьте соединение и попробуйте ещё раз.';
            } else if (error.response.status === 404) {
                report.error = 'Этот план уже удалён';
            } else if (error.response.data.hasOwnProperty('error')) {
                if (error.response.data.hasOwnProperty('message')) {
                    report.error = error.response.data['message'];
                } else {
                    report.error = error.response.data['error'];
                }

            } else {
                // TODO: Отсылать ошибку на сервер/в мониторинг
                report.error = 'Что-то пошло совсем не так...';
            }
        } catch (err) {
            // TODO: Отсылать ошибку на сервер/в мониторинг
            report.error = 'Что-то пошло совсем не так...';
        }
        report.loading = false;
    }
}
