<template>
<!--  <div class="auto_scroll">-->
    <div class="table-responsive" >
      <PlanEditorTable
          v-bind:monthInfo="monthInfo"
          v-bind:shifts="shifts"
          v-bind:employees="employees"
          v-bind:validate="false"
          v-bind:readOnly="true"
          v-bind:err_codes="[]"
          v-bind:shiftCount="shiftCount"
          v-bind:hourSlices="null"
          v-bind:shiftHours="shiftHours"
          v-bind:withHoursMan="withHoursMan"
      />
    </div>
<!--  </div>-->
</template>

<script>
import PlanEditorTable from './plan_editor/EmpTable'

import runner from '../model/PlanRunner';
import emptable from '../model/PlanEmpTable';
import shift from '../model/PlanShifts';
import months from '../model/PlanMonth';
import hoursMan from '../model/HoursMan';


export default {
  name: 'PlanViewer',
  components: {PlanEditorTable},
  props: ['init'],
  data: function () {
    let monthInfo = months.newState(this.init, null);
    return {
      // в каментах указаны ключи, которые добавляет каждая из моделей
      ...hoursMan.newState(this.init, null),  // withHoursMan, usedHourIntervals
      monthInfo, // month, currentMonth, daysCount, weekends, holyAdd, holyRemove, firstWeekDay
      ...emptable.newState(this.init, null, monthInfo.daysCount),  // employees, last_emp_id
      ...runner.newState(this.init),  // report, call_method, prev_plan
      ...shift.newState(this.init, monthInfo.daysCount),  // shifts, shift_seq_rules
      // revisions: this.init && this.init.revisions,  // url, count
    };
  },
  computed: {
    shiftCount() {
      return this.shifts.length;
    },
    shiftHours() {
      if (typeof(this.shifts[0].duration) === 'number') {
        return this.shifts.map((sh) => sh.duration);
      } else {
        return null;
      }
    },
  }
}
</script>
