<template>
<div>
<!-- Страница редактирования   -->
    <CreatePage
        v-bind:wizardFields="wizardFields"
        v-bind:title="title"
        v-if="show_mode === 'create'"
        @nextStep="afterCreatePage"
    />
    <ClonePage
        v-bind:tmpl="init.tmpl"
        v-else-if="show_mode === 'clone'"
    />
    <PlanEditor
        ref="planEditor"
        v-bind:init="init"
        v-bind:wizardFields="wizardFields"
        v-bind:title="title"
        v-bind:unsavingCause="unsavingCause"
        v-else-if="show_mode === 'edit'"
        @force-to-signup="callAuth('#sign-up', true)"
    />
    <RevisionsPage
        v-else-if="show_mode === 'revisions'"
        v-bind:init="init"
    />
    <PlanViewer
        v-else-if="show_mode === 'display'"
        v-bind:init="init"
    />
<!-- Модалки регистрации -->
    <AuthDispatch
        ref="auth"
        @force-reload="forceReload"
        @force-redirect="forceRedirect"
        @set-ignore-changes="ignoreChanges = true"
    />
</div>
</template>

<script>
import {setLocationHash} from './lib/nav';
import {unixTime} from "./lib/dates";
import {calcEditorTitle, newData} from './model/PlanInit';
import {markNotificationRead} from './model/NotificationAPI';
import {flushEvents, modalTracker, startSendingLoop, trackEvent} from './model/EventLog';

import PlanEditor from "./components/PlanEditor";
import PlanViewer from "./components/PlanViewer";
import ClonePage from "./components/ClonePage";
import CreatePage from "./components/CreatePage";
import AuthDispatch from './components/auth/Dispatch';
import RevisionsPage from "./components/RevisionsPage";


const PAGE_OPEN_TS = unixTime();

export default {
    name: "PlanPageApp",
    data: function () {
        let init = newData();
        let show_mode;
        let unsavingCause;  // Причина, по которой план в недосохранённом состоянии
        // {"error": 402, "message": "Сейчас на счету XX ₽..."}

        let edit_mode = window.EDITOR_MODE;
        if (init) {
            // FIXME: Что, если в init понадобятся данные для create?
            //    нужен способ явно задать show_mode
            if (init.hasOwnProperty('tmpl')) {
                show_mode = 'clone';
            } else if (edit_mode === 'revisions' || edit_mode === 'display') {
                show_mode = edit_mode;
            } else if (init.hasOwnProperty('prepopulated')) {
                // show_mode = 'create';
                show_mode = 'edit';
                unsavingCause = init.cause;
                init = init.prepopulated;
            } else {
                show_mode = 'edit';
            }
        } else {
            show_mode =  edit_mode || 'hidden';
        }
        return {
            init,
            show_mode,
            unsavingCause,
            title: calcEditorTitle(),
            wizardFields: {
                month: null,
                empCnt: null,
            },
            wizardHist: [],  // {mode: 'какой show_mode был', fields: ['какие поля были заполнены'], nextHash: 'хэш-следующей-страницы'}
            activeModalId: null,  // Какая модалка сейчас открыта и надо закрыть её на нажатие Back
            ignoreChanges: false,  // Нужно ли при уходе со страницы проверять на наличие изменений в редакторах
        }
    },
    components: {
        RevisionsPage,
        PlanEditor,
        PlanViewer,
        ClonePage,
        CreatePage,
        AuthDispatch,
    },
    methods: {
        afterCreatePage() {
            let page = {mode: 'create', fields: ['month', 'empCnt'], nextHash: '#editor_view'};
            this.wizardHist.push(page);
            setLocationHash("#editor_view");
            this.show_mode = 'edit';
            this.$nextTick(() => this.$refs.planEditor.$el.scrollIntoView());
        },

        // Управление навигацией по модальным окнам
        onModalShow(bvEvent, modalId) {
            modalTracker.open(modalId);
            this.activeModalId = modalId;
        },
        onModalHide(bvEvent, modalId) {
            modalTracker.close(modalId);
            if (this.activeModalId && this.activeModalId === modalId) {
              // FIXME: В каких случаях эта проверка не проходит?
              //  Именно на них происходит modal_id:undefined
                this.activeModalId = null;
                // Подразумевается что открытие любой модалки меняет hash.
                // Поэтому, на закрытие надо его вернуть.
                history.back();
            }
        },
        onBackPressed() {
            // console.log('popstate evt:', evt);
            // console.log('current hash:', window.location.hash);
            if (this.activeModalId) {
                let id = this.activeModalId;
                this.activeModalId = null;
                this.$bvModal.hide(id);
            // } else if (this.wizardHist) {
            // FIXME: проверять, что действительно пытается вернуться на страницу,
            //     а не открывает/закрывает модалки или переходит по ссылкам внутри страницы
            //     if (confirm('Вернуться на предыдущую страницу?\nВведённые изменения не сохранятся')) {
            //         let page = this.wizardHist.pop();
            //         for (let field of page.fields) {
            //             this.wizardFields[field] = null;
            //         }
            //         this.show_mode = page.mode;
            //     } else {
            //         let page = this.wizardHist[this.wizardHist.length - 1];
            //         setLocationHash(page.nextHash);
            //     }
            }
        },

        // Управление несохранёнными изменениями
        beforeUnload(event) {
            let ch = this.hasChanges();
            trackEvent('close_page',
                {'has_changes': ch, 'duration': unixTime() - PAGE_OPEN_TS});
            flushEvents();  // Успеть отправить события, пока страница не закрылась

            if (ch) {
                event.preventDefault();
                event.returnValue = 'В плане остались несохранённые изменения. Действительно закрыть страницу?';
                return 'В плане остались несохранённые изменения. Действительно закрыть страницу?';
            }
        },
        forceReload() {
            this.ignoreChanges = true;
            window.location.reload();
        },
        forceRedirect(url) {
            this.ignoreChanges = true;
            window.location = url;
        },

        hasChanges() {
            if (this.ignoreChanges) {
                return false;
            }
            let editor = this.$refs.planEditor;
            if (editor) {
                return editor.hasChanges;
            }
            return false;
        },

        // Управление аутентификацией
        addAuthEvents() {
            let links = document.querySelectorAll('a[data-auth]');
            let self = this;
            for (let el of links) {
                el.addEventListener('click', function (evt) {
                    evt.preventDefault();
                    let href = this.attributes['href'].value;
                    self.callAuth(href);
                })
            }
        },
        callAuth(href, isForced) {
            let hasChanges = this.hasChanges();
            let planData = null;
            if (hasChanges && this.$refs.planEditor) {
                planData = this.$refs.planEditor.getRequestData();
            }
            if (isForced) {
              modalTracker.setField('is_forced', true);
            }
            this.$refs.auth.callFunction(href, hasChanges, planData);
        },

        // Управление нотификациями
        addNotificationEvents() {
            let closers = document.querySelectorAll('button[data-mark-read-id]');
            for (let el of closers) {
                el.addEventListener('click', function (evt) {
                    evt.preventDefault();
                    let notification_id = this.attributes["data-mark-read-id"].value;
                    let msg = document.querySelector('#float-notification-' + notification_id);
                    msg.remove();
                    markNotificationRead(notification_id);
                })
            }
        },
        // Слежение за внешними ссылками
        addExternalLinkEvents() {
          let links = document.querySelectorAll('a[data-ext-link-name]');
          for (let el of links) {
            el.addEventListener('click', function () {
              let args = {'name': this.attributes["data-ext-link-name"].value};
              let cat = this.attributes["data-ext-link-category"];
              if (cat) {
                args['category'] = cat.value;
              }
              trackEvent('go_external', args);
            });
          }
        }
    },
    mounted() {
        window.addEventListener('beforeunload', this.beforeUnload);
        window.addEventListener('popstate', this.onBackPressed);
        this.$root.$on('bv::modal::shown', this.onModalShow);
        this.$root.$on('bv::modal::hidden', this.onModalHide);
        // TODO: Catch external bootstrap modals show/hide
        this.addAuthEvents();
        this.addNotificationEvents();
        this.addExternalLinkEvents();

        setTimeout(startSendingLoop, 2000);
    },
}
</script>
