import Vue from 'vue';

import BootstrapVue from 'bootstrap-vue';
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Nl2br from 'vue-nl2br';

import PlanPageApp from './PlanPageApp.vue';
import DailyList from "./components/DailyList";

Vue.component('nl2br', Nl2br);

Vue.use(BootstrapVue);

Vue.config.productionTip = false;

new Vue({
  render: h => {
    Vue.prototype.$static_root_url = window.STATIC_ROOT_URL;  // Переменная может ещё не существовать до этапа рендера
    return h(PlanPageApp);
  },
}).$mount(document.getElementById('plan_editor') ? '#plan_editor' : '#plan_editor_placeholder');

// TODO: Вынести сюда notifications отдельным приложением со своим элементом

if (document.getElementById('daily_list')) {
  new Vue({
    render: h => {
      return h(DailyList);
    },
  }).$mount('#daily_list');
}
