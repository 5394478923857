/// state для изображения анимаций

export default {
    newMotion(frames, frequency) {
        // frames - массив значений, которые будут соответствовать каждому кадру
        // frequency - сколько раз в секунду переходить к следующему кадру
        //    по-умолчанию - стандратная "кинолента" 24 кадра в секунду
        if (frequency === undefined) {
            frequency = 24;
        }
        let obj = {
            current: frames[0],  // Его нужно использовать в качестве свойства
            _frames: frames,
            _idx: 0,
            _period: 1000 / frequency,
            _timer: null,
        };
        obj.run = this.run.bind(obj);
        obj._nextFrame = this._nextFrame.bind(obj);
        return obj;
    },
    run() {  // Этим запускать "ролик"
        if (this._timer === null) {
            this._timer = setInterval(this._nextFrame, this._period);
        }
    },
    _nextFrame() {
        if (this._idx === this._frames.length) {
            clearInterval(this._timer);
            this.current = this._frames[0];
            this._idx = 0;
            this._timer = null
        } else {
            this._idx++;
            this.current = this._frames[this._idx];
        }
    }
}
