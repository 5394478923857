<template>
<div>
    <div class="form-group row">
        <div class="input-group col-lg-2 col-sm-4">
            <div class="input-group-prepend">
                <span class="plan_month">
                    <i class="fas fa-calendar-alt"></i>
                    {{ monthMap.get(selectedMonth) }}
                </span>
            </div>
        </div>

        <div class="col-lg-2 col-sm-8 text-left">
            <div class="badge badge_yellow" v-if="selectedMonth < currentMonth">Месяц уже прошёл</div>
            <div class="badge badge_yellow" v-else-if="selectedMonth === currentMonth">Месяц уже начался</div>
        </div>
<!--        <div class="col-lg-5 col-sm">-->
<!--&lt;!&ndash;            <i class="fas fa-cog"></i>&ndash;&gt;-->
<!--&lt;!&ndash;            <a href="#instr-modal-total" class="dashed_link m-1" v-b-toggle="'collapse-2'">Дополнительные настройки</a>&ndash;&gt;-->
<!--        </div>-->
        <div class="col-lg-5 col-sm-7 text-left">
            <b-form-input
                placeholder="Название"
                id="plan-title-editor-field"
                :value="planTitle"
                @change="$emit('set-plan-title', $event)"
                type="text"
                debounce="300"
                size="sm"
            />
            <b-tooltip
                target="plan-title-editor-field"
            >
                Необязательное. Можно указать чтобы отличать планы за один и тот же месяц
            </b-tooltip>
        </div>

        <div class="col-lg-3 col-sm-5 text-right">
            <a href="#" class="dashed_link"
                v-on:click="$event.preventDefault() || $emit('set-status-for-all', 'free')"
            >
                <i class="fas fa-eraser"></i>&nbsp;
                <span>Очистить план</span>
            </a>
        </div>
    </div>
</div>
</template>

<script>
import months from '../../model/PlanMonth';

export default {
    name: "PlanEditorHeader",
    props: ['selectedMonth', 'currentMonth', 'planTitle'],
    data: function () {
        let monthMap = months.genMonths(this.selectedMonth);
        return {
            monthMap: monthMap,
        }
    },
    methods: {
    },
}
</script>

<style scoped>

</style>
