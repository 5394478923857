import moment from 'moment';

moment.locale('ru');

const RU_DAY_NAMES = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']

export default {
    newState(init, wizardFields) {
        let state = {
            month: '', // Выбранный месяц в формате YYYYMM.
            ...this.nearbyDates(),
            weekends: [],  // Числа, на которые приходятся выходные
            weekdayNames: [],  // Краткие названия дней недели для каждого дня в месяце
            firstWeekDay: null,  // С какого дня недели начинается месяц (idx, 0 - понедельник)
            // праздники и переносы
            holyAdd: [],  // Какие доп.выходные есть в месяце
            holyRemove: [],  // Какие выходные в месяце перенесены
        };

        let month;
        if (init && init.month) {
            month = init.month;
        } else if (wizardFields && wizardFields.month) {
            month = wizardFields.month;
        } else {
            month = this.defaultMonthSelected().format(this.VALUE_MONTH_FMT);
        }

        this.setMonth(state, month);
        return state;
    },

    defaultMonthSelected() {
        let curDay = moment();
        if (curDay.date() > 7) {
            // В первую неделю могут всё ещё планировать текущий месяц
            curDay.add({month: 1});
        }
        return curDay.startOf('month');
    },

    nearbyDates() {
        let currentMonth = moment().startOf('month');
        return {
            prevMonth: currentMonth.clone().add({month: -1}).format(this.VALUE_MONTH_FMT),
            currentMonth: currentMonth.format(this.VALUE_MONTH_FMT),
            nextMonth: currentMonth.clone().add({month: 1}).format(this.VALUE_MONTH_FMT),
            currentDay: moment().date(),  // zero based
            endDay: moment().endOf('month').date(),  // zero based
        }
    },

    tmplState(tmpl) {
        let tmplFirstDay = this.str2moment(tmpl.period);
        let suggestedDay = tmplFirstDay.clone().add({month: 1});
        suggestedDay = moment.max(this.defaultMonthSelected(), suggestedDay);
        return {
            ...this.nearbyDates(),
            selected: suggestedDay.format(this.VALUE_MONTH_FMT),
            tmplPeriodName: tmplFirstDay.format(this.HUMAN_MONTH_FMT)
        };
    },

    VALUE_MONTH_FMT: 'YYYYMM',
    HUMAN_MONTH_FMT: 'MMMM YYYY',

    HOLY_MONTHS: {  // TODO: получение с бэкенда
        '201901': [1, 2, 3, 4, 5, 6, 7, 8],
        '201902': [23],
        '201903': [8],
        '201905': [1, 2, 3, 9, 10],  // 2,3 - за 5, 6 января; 10 - за 23 февраля
        '201906': [12],
        '201911': [4],
        // 2020
        '202001': [1, 2, 3, 4, 5, 6, 7, 8],
        '202002': [23, 24],  // 24 - за понедельник
        '202003': [8, 9],  // 9 - за воскресенье
        '202005': [1, 4, 5, 9, 11],  //  11 - за воскресенье; 4,5 - хз за какой день
        '202006': [12],
        '202011': [4],
        // 2021
        '202101': [1, 2, 3, 4, 5, 6, 7, 8],
        '202102': {'add': [22, 23], 'remove': [20]},  // 22 - перенос с 20(сб)
        '202103': [8],
        '202105': [1, 3, 4, 5, 6, 7, 9, 10],  // 3 - за 1; 10 - за 9
        '202106': [12, 14],  // 12 - за 14
        '202111': [4, 5],
        '202112': [31],
        // 2022
        '202201': [1, 2, 3, 4, 5, 6, 7, 8],
        '202202': [23],
        '202203': {'add': [7, 8], 'remove': [5]},  // перенос с 5(сб) на 7
        '202205': [1, 2, 3, 9, 10],  // 3 и 10 - за 1 и 2 января
        '202206': [12, 13],  // 13 - за 12
        '202211': [4],
        // 2023
        '202301': [1, 2, 3, 4, 5, 6, 7, 8],
        '202302': [23, 24],  // за 1 января
        '202303': [8],
        '202305': [1, 8, 9],  // за 8 января
        '202306': [12],
        '202311': [4, 6],  // 6 - за 4
        // 2024
        '202401': [1, 2, 3, 4, 5, 8],
        '202402': [23],
        '202403': [8],
        '202404': {'add': [29, 30], 'remove': [27]},
        '202405': [1, 9, 10],
        '202406': [12],
        '202411': {'add': [4], 'remove': [2]},
        '202412': {'add': [30,31], 'remove': [28]},
        // 2025
        '202501': [1, 2, 3, 6, 7, 8],
        '202502': [24],
        '202503': [10],
        '202505': [1, 2, 9],
        '202506': [12,13],
        '202511': [4],
    },

    str2moment(month_key) {
        // Первый день месяца, обозначаемого ключём month_key
        month_key = month_key + '01';
        let fmt = this.VALUE_MONTH_FMT + 'DD';
        return moment(month_key, fmt);
    },

    key2human(month_key) {
        return this.str2moment(month_key).format(this.HUMAN_MONTH_FMT);
    },

    setMonth(local_state, month_key) {
        local_state.month = month_key;
        let corrections = this.HOLY_MONTHS[month_key] || [];
        if (Array.isArray(corrections)) {
            local_state.holyAdd = corrections;
            local_state.holyRemove = [];
        } else {
            local_state.holyAdd = corrections.add;
            local_state.holyRemove = corrections.remove;
        }

        let firstDay = this.str2moment(month_key),
            daysNum = firstDay.daysInMonth(),
            weekends = [],
            weekdayNames = [],
            wd = firstDay.weekday();

        local_state.daysCount = daysNum;
        local_state.firstWeekDay = wd;

        for (let n = 1; n <= daysNum; n++) {
            weekdayNames.push(RU_DAY_NAMES[wd]);
            if (wd === 5 || wd === 6) {
                weekends.push(n);
            }
            wd = (wd+1) % 7;
        }
        local_state.weekends = weekends;
        local_state.weekdayNames = weekdayNames;
    },

    genMonths(initialMonth) {
        // generate mapping of 12 most closest upcoming months to num_of_days and human readable format
        let firstDay = moment().startOf('month');
        if (initialMonth) {
            firstDay = moment.min(firstDay, moment(initialMonth, "YYYYMM"));  // ...и надеемся, что "года хватит всем"
        }

        let monthMap = new Map();
        for (let n = 1; n < 13; n++) {
            monthMap.set(
                firstDay.format(this.VALUE_MONTH_FMT),
                firstDay.format(this.HUMAN_MONTH_FMT)
            );
            firstDay.add({month: 1});
        }
        return monthMap;
    },
}
