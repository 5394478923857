<template>
<div class="scheduler_bg">
    <div class="auto_scroll">

        <h2>Версии на {{ planMonth }}</h2>
        <h4 v-if="planTitle">
            план
            <i class="fas fa-angle-double-left"></i>
            {{ planTitle }}
            <i class="fas fa-angle-double-right"></i>
        </h4>

        <p class="help_text">
          <i class="fas fa-question-circle"> </i>
          <a target="_blank" href="/tutorial/schedule_revisions/#content-start">
            Как пользоваться версионированием планов
          </a>
        </p>

        <p v-if="!hasRevisions" class="help_text">
            <i class="fas fa-exclamation-circle"> </i>
            Этот план сохранялся только один раз и версия у него только одна.
        </p>

        <div class="paddings_15">
            <b-button-group size="sm">
                <b-button :href="init.urls.edit_url" class="btn_yellow">
                    <i class="far fa-edit"></i> Править актуальную версию
                </b-button>
                <b-button :href="init.urls.display_url" class="btn_yellow">
                    <i class="fas fa-external-link-alt"></i> Смотреть актуальную версию на отдельной странице
                </b-button>
            </b-button-group>
        </div>

      <template v-for="(rev, idx) of revisions">
        <Revision
            v-if="idx < showCount"
            v-bind:key="rev.ts"
            v-bind:revision="rev"
            v-bind:monthInfo="monthInfo"
            v-bind:guid="guid"
            v-bind:redirect_url="init.urls.display_url"
        />
        <div v-if="idx == showCount">
          <b-button
              v-bind:key="showMoreRevButton"
              v-on:click="showCount += showStep"
              class="btn_yellow"
          >
            <i class="fa fa-angle-double-down"></i>
            Показать предыдущие версии...
          </b-button>
        </div>
      </template>
    </div>
</div>
</template>

<script>
import PlanMonth from "../model/PlanMonth";
import months from "../model/PlanMonth";
import hoursMan from "../model/HoursMan";
import emptable from "../model/PlanEmpTable";
import pattern from "../model/PlanEmpPattern";
import shift from "../model/PlanShifts";
import Revision from "./plan_editor/Revision";

export default {
    name: "RevisionsPage",
    props: ["init"],
    components: {
        Revision,
    },
    data() {
        let actual = this.init.actual;
        let monthInfo = months.newState(actual, null);
        let self = this;
        let revisions = this.init.revisions.map(function (rev) {
            return self.initRevision(rev, monthInfo.daysCount, actual.features);
        });
        revisions.push(this.initRevision(actual, monthInfo.daysCount))
        revisions.reverse();
        return {
            planMonth: PlanMonth.key2human(actual.month),
            planTitle: actual.title,
            guid: this.init.urls.guid,

            ...hoursMan.newState(actual, null),  // withHoursMan, usedHourIntervals
            monthInfo: monthInfo, // month, currentMonth, daysCount, weekends, holyAdd, holyRemove, firstWeekDay
            revisions: revisions,
            showCount: 15,
            showStep: 10,
        };
    },
    methods: {
        initRevision(rev, daysCount, features) {
            if (features) {
                rev = {...rev, features};
            }
            return {
                ...emptable.newState(rev, null, daysCount),  // employees, last_emp_id
                ...pattern.newState(rev), // empPattern
                ...shift.newState(rev, daysCount),  // shifts, shift_seq_rules
                ...hoursMan.newState(rev, null),  // withHoursMan, usedHourIntervals
                ts: rev.ts || null,
                label: rev.label,
            }
        }
    },
    computed: {
        hasRevisions() {
            return this.init.revisions.length > 0;
        }
    }
}
</script>
