import axios from 'axios';
import Cookie from 'js-cookie';

import {shiftStatuses, codeToStatus} from '../lib/shift_statuses';
import emp_pattern from './PlanEmpPattern'

export default {
    newState(init) {
        return {
            report: {
                run_message: '',  // Результаты или ошибки составления плана, отображаемые для пользователя
                save_message: '',  // Результаты или ошибки сохранения, отображаемые для пользователя
                fix_info: '', // Способ исправить ошибку. Форматируется как html, может содержать ссылки
                just_finished: false,  // Только что завершился (нужно чем-то визуализировать)
                status: '',  // Каким стилем отображать message
                err_codes: [], // Структурированная инфа об ошибках, которую ui может использовать для подсветки ошибок
                loading: false,  // Состояние загрузки (во время true интерфейс должен быть заблокирован)
                last_uri: init ? init.url : '', // Адрес последнего составленного плана
            },
            call_method: window.MY_URLS && window.MY_URLS.scheduleCallMethod,  // CREATE, UPDATE, undefined
            prev_plan: init && init.prev_guid ?
                {guid: init.prev_guid, url: init.prev_url, consider: true} :
                {guid: null, url: null, consider: false},
        }
    },

    // Отправка запросов в API

    getSchedulerData(state) {
      let data = {
        month: state.monthInfo.month,
        title: state.planTitle,
        shifts: state.shifts,
        shift_seq_rules: state.shift_seq_rules,
        employees: state.employees.map((emp) => {
            return {
                name: emp.name,
                rate: emp.rate,
                pattern: emp_pattern.toApi(emp.pattern, false),
                shift_prio: emp.shift_prio,
            }
        }),
        emptable: state.employees.map((emp) => {
          return emp.shifts.slice(0, state.monthInfo.daysCount).map((shift) => {
              if (typeof(shift.status) === 'object') {
                  return shift.status;
              } else {
                  return shiftStatuses[shift.status].code;
              }
          });
        }),
        emp_pattern: emp_pattern.toApi(state.empPattern, true),
        horizon: state.horizon,
      };

      if (state.prev_plan.consider) {
          data.prev_guid = state.prev_plan.guid;
      }

      if (state.withHoursMan) {
          data.features = {hours_man: true};
      }

      return data;
    },

    makeSchedulerRequest(state, mode) {
      let data = this.getSchedulerData(state);
      let url = '';

      if (mode === 'FILL') {
          if (state.call_method === 'CREATE') {
              url = window.MY_URLS.scheduleCall + 'fill';
          } else if (state.call_method === 'UPDATE') {
              url = window.MY_URLS.scheduleCall + 'fill?guid=' + window.MY_URLS.guid;
          } else {
              url = '/storage/fill/';
          }
      } else {
          if (state.call_method === 'CREATE') {
              url = window.MY_URLS.scheduleCall + 'save';
          } else if (state.call_method === 'UPDATE') {
              url = window.MY_URLS.scheduleCall + 'save?guid=' + window.MY_URLS.guid;
          } else {
              url = '/storage/create/';  // FIXME: Не должен сюда попадать никогда
          }
      }

      return {
        url: url,
        contentType: 'application/json; charset=utf-8',
        method: 'POST',
        timeout: 25000,
        headers: {"X-CSRFToken": Cookie.get('csrftoken')},
        data: data,
        dataType: "json"
      };
    },

    runScheduler(state, mode) {
        /// TODO: Check if has some meaningful changes
        state.report.run_message = '';
        state.report.save_message = '';
        state.report.fix_info = '';
        state.report.just_finished = false;
        this.setMessage(state, mode, 'Секундочку...');
        state.report.status = 'loading';
        state.report.loading = true;
        state.report.err_codes.splice(0);
        state.prepopulated = false;  // Если он был предзаполнен,
        // то теперь эта инфа не нужна. Она и так вылезет в report

        axios(this.makeSchedulerRequest(state, mode))
            .then(this.onSchedulerSuccess.bind(this, state, mode))
            .catch(this.onSchedulerError.bind(this, state, mode));
    },

    // Обработка ответа

    onSchedulerSuccess(state, mode, response) {
      if (state.call_method === 'CREATE') {
          // Отправляем события в системы аналитики
          if (window.ym) {
              try {
                  window.ym(27510897, 'reachGoal', 'schedule_created', {});
              } catch (e) {
                  null;
              }
          }

          if (window.ga) {
              try {
                  window.ga('send', {
                      hitType: 'event',
                      eventCategory: 'schedule',
                      eventAction: 'created',
                  });
              } catch (e) {
                  null;
              }
          }
      }

      if (state.call_method === 'CREATE' && mode === 'SAVE') {
        state.hasChanges = false;  // Чтобы не появлялась предупреждалка о покидании страницы с несохранёнными изменениями
        window.location = response.data.url;
        return; // Кажется, не нужен, так как мы уже и так переместимся на другую страницу
      }
      state.report.status = 'success';
      state.report.run_message = '';
      state.report.save_message = '';
      state.report.fix_info = '';
      state.report.loading = false;
      state.report.just_finished = true;
      setTimeout(() => {state.report.just_finished = false}, 1000);

      if (mode === 'FILL') {
          this.fillShifts(state, response.data.emptable);
          state.hasChanges = true;
          state.report.run_message = 'Готово';
      } else if (mode === 'SAVE') {
          // FIXME: Обозначать, сохранён валидный план, или нет
          state.report.last_uri = response.data.url;
          state.report.save_message = 'Сохранено';
          state.hasChanges = false;
          if (state.revisions) {
              state.revisions.count++;
          }
      }
    },

    fillShifts(state, emptable) {
      state.employees.forEach((emp, idx) => {
        var shifts = emp.shifts;
        emptable[idx].forEach((act, idx) => {
          shifts[idx].status = codeToStatus(act);
        });
      })
    },

    onSchedulerError(state, mode, error) {
        state.report.status = 'error';
        try {
            if (!error.response) {
                this.setMessage(state, mode, 'Не удаётся выполнить. Проверьте соединение и попробуйте ещё раз.');
            } else if (error.response.status === 404) {
                this.setMessage(state, mode, 'Этот план уже удалён');
            } else if (error.response.data['error'] && error.response.data['message']) {
                if (error.response.data['error'] >= 500) {
                    // TODO: Отсылать ошибку на сервер/в мониторинг
                    this.setMessage(state, mode, 'Что-то пошло совсем не так...');
                } else {
                    this.setMessage(state, mode, error.response.data['message']);
                    if (error.response.data['error'] === 402) {
                        state.report.fix_info = this.paymentInfo;
                    }
                }
            } else {
                let errors = error.response.data['errors'];
                this.setMessage(state, mode, errors.join('\n'));
                // Подсвечиваем ошибки в валидационных полях
                let err_codes = error.response.data['err_codes'];
                if (err_codes && err_codes.length > 0) {
                    state.report.err_codes.push(...err_codes);
                }
            }
        } catch (err) {
            // TODO: Отсылать ошибку на сервер/в мониторинг
            this.setMessage(state, mode, 'Что-то пошло совсем не так...');
        }
        state.report.just_finished = true;
        setTimeout(() => {state.report.just_finished = false}, 1000);
        state.report.loading = false;
    },

    // Управление виджетами

    setMessage(state, mode, msg) {
        if (mode === 'SAVE') {
            state.report.save_message = msg;
        } else {
            state.report.run_message = msg;
        }
    },

    setHorizon(state, changes) {
        state.horizon.from_day = changes.from_day;
        state.horizon.to_day = changes.to_day;
        if (typeof(changes.consider_prev) === "boolean") {
            state.prev_plan.consider = changes.consider_prev;
        }
        if (changes.instant_recalc) {
            this.runScheduler(state, 'FILL');
        }
    },

    paymentInfo: 'Чтобы не терять изменения, можно <a href="/billing/payment/" target="_blank">оплатить в отдельном окне</a> и вернуться сюда',
}

