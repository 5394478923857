import axios from 'axios';


export default {
    newState() {
        return {
            list: window.TUTORIAL_LIST,
            loaded: {},  // key: "html"
            current: null,  // html, который надо отображать прямо сейчас
            error: null,
        }
    },
    URL_PREFIX: "/tutorial/embed-",
    loadChapter(state, key) {
        if (state.loaded.hasOwnProperty(key)) {
            state.current = state.loaded[key];
        } else {
            state.current = null;
            state.error = null;
            let request = {
                url: this.URL_PREFIX + key,
                method: 'GET',
            };
            axios(request).then(function(response) {
                state.loaded[key] = response.data;
                state.current = response.data;
            }).catch(function (error) {
                if (error.response) {
                    state.error = error.response.status;
                } else {
                    state.error = 'no_response';
                    // TODO: сделать избирательные перезапросы.
                    // setTimeout(self.loadChapter.bind(self, state, key), 250);
                }
            });
        }
    }
}
