import Vue from 'vue'

import {zeros} from "../lib/arrays";

export default {
    newState(init, daysCount) {
        let demands;  // Количество сотрудников требуемых в указанный день
        let week_demands;  // Требования по дням недели
        if (init && init.demands) {
            demands = init.demands;
            if (demands.length < daysCount) {
                let sum = demands.reduce(function (a, b) {
                    return a + b;
                });
                let default_demand = Math.round(sum / demands.length);
                demands = demands.concat(Array.from(
                    {length: daysCount - demands.length},
                    () => default_demand));
            }
            week_demands = init.week_demands ? new Map(Object.entries(init.week_demands)) : new Map();
        } else {
            demands = zeros(daysCount);
            week_demands = new Map();
        }
        return {demands, week_demands};
    },

    singleChange(state, event) {
        let shift_idx = event.sh_idx ? event.sh_idx - 1 : 0;
        state.shifts[shift_idx].demands.splice(event.idx, 1, event.value)
    },

    batchChange(state, event) {
        let shift_idx = event.sh_idx ? event.sh_idx - 1 : 0;
        let shift = state.shifts[shift_idx];
        let month = state.monthInfo;
        shift.week_demands.set(String(event.daysType), event.value);

        if (event.daysType === 'all') {
            shift.demands = Array.from({length: month.daysCount}, () => event.value);
        } else if (event.daysType === 'holidays') {
            for (let n of month.weekends.concat(month.holyAdd)) {
                if (!month.holyRemove.includes(n)) {
                    // n-1 потому что holidays считаются от единицы, а индексы массива от 0
                    shift.demands.splice(n - 1, 1, event.value);
                }
            }
        } else if (event.daysType === 'workdays') {
            shift.demands = shift.demands.map((d, i) => {
                // i+1 потому что holidays считаются от единицы, а индексы массива от 0
                if (month.holyRemove.includes(i + 1)) {
                    return event.value;
                } else if (month.weekends.includes(i + 1) || month.holyAdd.includes(i + 1)) {
                    return d;
                } else {
                    return event.value;
                }
            });
        } else {
            let idx = event.daysType - month.firstWeekDay;
            if (idx < 0) {
                idx = idx + 7;
            }
            for (let i = idx; i < month.daysCount; i = i + 7) {
                Vue.set(shift.demands, i, event.value);
            }
        }
    },
}
