<template>
<div class="white_bg">
    <div class="container paddings_50">
        <h2>Клонирование плана</h2>
        <div class="row justify-content-md-center div_clone_page">
            <div class="border shadow rounded col-md-4 col-sm-8 step_month_radio">
                <h5 class="text-left">Выберите месяц для нового плана</h5>
                <hr>
                <MonthList
                    v-bind:month-state="monthState"
                    v-bind:tmpl="tmpl"
                />
            </div>
            <div class="border shadow rounded col-md-4 col-sm-8 div_text_btn">
                <p>Будет создан новый план на основе
                    <a :href="tmpl.url" target="_blank">исходного за {{ monthState.tmplPeriodName }}</a>.
                </p>
                <!-- TODO: смотреть, какие фичи используются в предыдущем плане и перечислять их -->
                <p>В новый план будут скопированы:</p>
                <ul class="text-left">
                    <li>настройки смен,</li>
                    <li>настройки периодичности работы,</li>
                    <li>требования по количеству сотрудников на каждый день.</li>
                </ul>
                <!-- TODO: Ссыли со всплывающими модалками с хелпом по фиче -->

                <p>
                    <b-checkbox v-model="withEmps" :class="{'text-muted': !withEmps}">
                        Скопировать сотрудников с их персональными настройками
                    </b-checkbox>
                    <b-form-group
                        v-if="!withEmps"
                        label="Завести новых сотрудников:"
                    >
                        <b-form-input
                            v-model="empCnt"
                            type="text"
                            placeholder="количество"
                        />
                    </b-form-group>
                </p>

                <p v-if="canHoursMan">
                    <b-checkbox v-model="withHoursMan">Почасовой график</b-checkbox>
                </p>

                <p v-if="error_msg" class="error">
                    <i class="fas fa-exclamation-circle"
                       :style="'font-size: ' + errMotion.current + 'px'"
                    ></i>
                    {{ error_msg }}
                </p>
                <b-button
                    class="btn_default"
                    :disabled="cloneInProgress"
                    @click="performClone"
                >
                    Склонировать
                </b-button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import months from '../model/PlanMonth';
import {parseEmpCnt} from '../model/PlanCreation';

import axios from "axios";
import Cookie from "js-cookie";
import MonthList from "./plan_editor/MonthList";
import motions from "../lib/motions";

export default {
    name: "ClonePage",
    components: {MonthList},
    props: ['tmpl'],
    data() {
        // В tmpl находятся "guid", "period", "url"
        return {
            // Персональная фича для ручного задания часов работы:
            canHoursMan: window.FEATURES && window.FEATURES.includes('hours_man'),
            withHoursMan: this.tmpl.features && this.tmpl.features.hours_man,
            // Параметры клонирования
            monthState: months.tmplState(this.tmpl),
            withEmps: true,
            empCnt: '',
            // Статус клонирования:
            cloneInProgress: false,
            errMotion: motions.newMotion([12, 12, 13, 13, 13, 14, 14, 15, 15, 15, 15, 14, 14, 13, 13, 13]),
            error_msg: '',
        };
    },
    methods: {
        // Обращение к api
        performClone() {
            this.error_msg = ''
            let request = {
                url: window.MY_URLS.scheduleCall + 'clone?guid=' + this.tmpl.guid,
                contentType: 'application/json; charset=utf-8',
                method: 'POST',
                headers: {"X-CSRFToken": Cookie.get('csrftoken')},
                data: {
                    month: this.monthState.selected,
                    with_hours_man: this.withHoursMan
                },
                dataType: "json"
            };
            if (!this.withEmps) {
                let empCnt = parseEmpCnt(this);
                if (this.error_msg) {
                    this.errMotion.run();
                    return;
                } else {
                    request.data.new_emps_count = empCnt
                }
            }
            this.cloneInProgress = true;
            axios(request)
                .then(this.onCloneSuccess.bind(this))
                .catch(this.onCloneError.bind(this));
        },
        onCloneSuccess(response) {
            window.location = response.data.url;
        },
        onCloneError(error) {
            if (error.response && error.response.data['error'] && error.response.data['message']) {
              if (error.response.data['error'] >= 500) {
                // TODO: Отсылать ошибку на сервер/в мониторинг
                this.error_msg = 'Не удалось склонировать. Попробуйте чуть позже';
              } else {
                this.error_msg = error.response.data['message'];
              }
            } else {
              // TODO: Отсылать ошибку на сервер/в мониторинг
              this.error_msg = 'Не удалось склонировать. Попробуйте чуть позже';
            }
            this.errMotion.run();
            this.cloneInProgress = false;
        }
    }
}
</script>

