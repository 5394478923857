import Vue from 'vue';

import empTable from './PlanEmpTable';
import demand from './PlanDemand';



export default {
    newState(init, daysCount) {
        let newShift = this.newShift
        let shifts = init && init.shifts.map(function(sh) {
            return newShift(sh, daysCount)
        });
        return {
            shifts: shifts || [newShift(null, daysCount)],
            shift_seq_rules: (init && init.shift_seq_rules) || [],
        }
    },

    newShift(init, daysCount) {
        return {
            title: init ? init.title : '',
            duration: init && typeof(init.duration) === 'number'? init.duration : null,
            ...demand.newState(init, daysCount),  // demands, week_demands
        }
    },

    shiftName(shift, idx) {
        if (shift.title) {
            return idx + ' - ' + shift.title;
        } else {
            return idx + ' смена';
        }
    },

    applyShifts(state, newShifts, daysCount) {
        let old_shift_count = state.shifts.length;
        let renaming = new Array(old_shift_count+1);
        renaming[0] = 0;  // Ячейка для Free. С ней ничего не делаем.

        newShifts.forEach((sh, idx) => {
            if (sh.origin_id <= old_shift_count) {
                renaming[sh.origin_id] = idx+1;
                let orig_shift = state.shifts[sh.origin_id-1];
                orig_shift.title = sh.title;
                orig_shift.duration = sh.duration;
            } else {
                state.shifts.push(this.newShift(sh, daysCount));
            }
        });

        let new_idx = 0;
        for (let orig_idx = 1; orig_idx <= old_shift_count; orig_idx++) {
            if (!renaming[orig_idx]) {
                state.shifts.splice(new_idx, 1);
            } else {
                new_idx++;
            }
        }

        empTable.renameMultiStatuses(state, renaming);
        empTable.reorderShiftPrios(state, renaming, newShifts.length);
        this.renameSeqRules(state, renaming);
    },

    // Shift Sequence Rules

    CASE_OPTIONS: [
        { value: 'before', text: 'до' },
        { value: 'after', text: 'после' },
    ],

    RULE_OPTIONS: [
        { value: 'forbid', text: 'нельзя...' },
        { value: 'only', text: 'можно только...' },
        { value: 'day_off', text: 'нужен выходной' },
        { value: 'must_work', text: 'точно работает' },
    ],

    WITH_OTHERS: ['forbid', 'only'],

    renameSeqRules(state, renaming) {
        let rule_idx = 0;
        let seq_rules = state.shift_seq_rules;
        while (rule_idx < seq_rules.length) {
            let rule = seq_rules[rule_idx];
            let new_base = renaming[rule.base];
            if (!new_base) {
                seq_rules.splice(rule_idx, 1);
                continue;
            } else {
                rule.base = new_base;
            }

            if (!this.WITH_OTHERS.includes(rule.rule)) {
                rule_idx++;
                continue;
            }

            let new_others = [];
            for (let old_idx of rule.others) {
                let new_idx = renaming[old_idx]
                if (new_idx) {
                    new_others.push(new_idx);
                }
            }
            if (new_others.length > 0) {
                rule.others = new_others;
            } else {
                seq_rules.splice(rule_idx, 1);
                continue;
            }
            rule_idx++;
        }
    },
    setSeqRules(state, newRules) {
        // Возвращает флаг, изменилось ли что-то в правилах
        let old_rules = state.shift_seq_rules;
        if (old_rules.length !== newRules.length) {
            old_rules.splice(0);
            for (let rule of newRules) {
                old_rules.push({
                    case: rule.case,
                    base: rule.base,
                    rule: rule.rule,
                    others: this.WITH_OTHERS.includes(rule.rule) ? rule.others : [],
                });
            }
            return true;
        } else {
            let hasChanges = false;
            for (let [idx, rule] of newRules.entries()) {
                let replacer = {case: rule.case, base: rule.base, rule: rule.rule,
                    others: this.WITH_OTHERS.includes(rule.rule) ? rule.others : []};
                if (old_rules[idx] !== replacer) {
                    Vue.set(old_rules, idx, replacer);
                    hasChanges = true;
                }
            }
            return hasChanges;
        }
    }
}
