import Cookie from "js-cookie";
import axios from "axios";

export function sendAuthRequest(url, state, successCallback, genericErrMsg) {
    let data = new FormData();
    for (let f of Object.values(state.fields)) {
        data.append(f.name, f.value);
    }
    let request = {
        url: url, // FIXME: Получать такие урлы из Django
        // contentType: 'application/json; charset=utf-8',
        method: 'POST',
        headers: {
            "X-CSRFToken": Cookie.get('csrftoken'),
            "X-Requested-With": "XMLHttpRequest",
        },
        data: data,
        dataType: "form",
    };
    axios(request).then(successCallback).catch(function (error) {
        if (error.response.status === 400) {
            let data_fields = error.response.data.form.fields;

            for (let f of Object.values(state.fields)) {
                if (data_fields.hasOwnProperty(f.name)) {
                    f.errors.splice(0);
                    f.errors.push(...data_fields[f.name].errors);
                }
            }

            state.errors.splice(0);
            let global_errors = error.response.data.form.errors;
            state.errors.push(...global_errors);
        // } else if (error.response.status === 402) {  // не нужен?
        } else {
            state.errors.splice(0);
            state.errors.push(genericErrMsg);
        }
        state.inProgress = false;
    });
}
