<template>
<div class="container">
    <div class="row">
        <div class="col-md-6 col-xs">
            <div class="help_text">
                <p><i class="fas fa-exclamation-circle"></i>
                    Нужное количество сотрудников на конкретные дни можно указать в последней строке таблицы.
                </p>
                <p>А здесь можно указать регулярные требования.</p>
            </div>
            <hr/>
            <div>
                <WeeklyDemandCell
                    v-for="sel in wd.batchedAll"
                    :key="sel.key"
                    class="input-group"
                    v-bind:sel="sel"
                    v-bind:sh_idx="wd.sh_idx"
                />
            </div>
            <hr/>
            <div>
                <WeeklyDemandCell
                    v-for="sel in wd.batchedHalf"
                    :key="sel.key"
                    class="input-group"
                    v-bind:sel="sel"
                    v-bind:sh_idx="wd.sh_idx"
                />
            </div>
        </div>
        <div class="col-md-6 col-xs">
            <div>
              <p></p>
                <WeeklyDemandCell
                    v-for="sel in wd.batchedDays"
                    :key="sel.key"
                    class="input-group"
                    v-bind:sel="sel"
                    v-bind:sh_idx="wd.sh_idx"
                />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import WeeklyDemandCell from "./WeeklyDemandCell"

export default {
    name: 'WeeklyDemandTab',
    components: {WeeklyDemandCell},
    props: ['wd'],
}
</script>
